import React, { PureComponent } from "react";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { InfoCircle } from "react-bootstrap-icons";

const renderTooltip = ({ props, text }) => (
  <Tooltip id="button-tooltip" {...props}>
    {text}
  </Tooltip>
);

const renderColor = (score) => {
  if (score <= 2) {
    return "#FC0002";
  }
  if (score > 2 && score <= 3) {
    return "#F96264";
  }
  if (score > 3 && score <= 5) {
    return "#FCD703";
  }
  if (score > 5 && score <= 8) {
    return "#66BB03";
  }
  if (score > 8 && score <= 10) {
    return "#4C8E00";
  }
  return "";
};

export default class Example extends PureComponent {
  render() {
    const { title, currentValue, tooltip } = this.props;

    const data = [
      {
        name: title,
        value: currentValue,
        fill: renderColor(currentValue),
      },
    ];

    return (
      <>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip({ text: tooltip })}
        >
          <h5 className="mt-0">
            {title} <InfoCircle />
          </h5>
        </OverlayTrigger>
        <div className="radial-bar-chart relative">
          <RadialBarChart
            width={200}
            height={200}
            cx="50%"
            cy="50%"
            innerRadius="70%"
            outerRadius="95%"
            barSize={30}
            data={data}
            className="mx-auto"
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 10]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar
              minAngle={50}
              isAnimationActive={true}
              background
              clockWise={true} 
              dataKey="value"
            />
          </RadialBarChart>
          <div className="count">{currentValue}</div>
        </div>
      </>
    );
  }
}
