import React, { useState } from "react";

interface IProps {
  className: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const Button: React.FC<IProps> = ({
  className,
  children,
  onClick,
  disabled,
}) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <button
      className={`${className} ${active ? "active" : ""}`}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      {children}
    </button>
  );
};

export default Button;
