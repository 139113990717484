import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BehaviorCounts from "./BehaviorCounts";
import Frequencies from "./Frequencies";
import Metrics from "./Metrics";
import Counts from "./Counts";
import Table from "react-bootstrap/Table";
import { ArrowRight } from "react-bootstrap-icons";

const Dashboard = ({ data }) => {
  // LIVE DATA
  const storedData = data;
  
  return (
    <div className="dashboard">
      {storedData !== null ? (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Conversation Analytics</Accordion.Header>
            <Accordion.Body className="pa4">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
              >
                <Row>
                  <Col sm={3} className="pr5 pb4">
                    <Nav variant="underline" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Behavior Counts</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Frequencies</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Metrics</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Counts</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <h4 className="mt-0">Behavior Counts</h4>
                        <BehaviorCounts
                          apiData={storedData.conversation_analytics}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <h4 className="mt-0">Frequencies</h4>
                        <Frequencies
                          apiData={storedData.conversation_analytics}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <h4 className="mt-0">Metrics</h4>
                        <Metrics apiData={storedData.conversation_analytics} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <h4 className="mt-0">Counts</h4>
                        <Counts apiData={storedData.conversation_analytics} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Role Analytics</Accordion.Header>
            <Accordion.Body className="pa4">
              <Tabs
                defaultActiveKey={
                  storedData.role_analytics[0].role === null
                    ? "Unspecified Role"
                    : storedData.role_analytics.role
                }
                id="uncontrolled-tab-example"
                className="mb-5"
              >
                {storedData.role_analytics.map((role, index) => {
                  return (
                    <Tab
                      eventKey={
                        role.role === null ? "Unspecified Role" : role.role
                      }
                      title={
                        role.role === null ? "Unspecified Role" : role.role
                      }
                      key={index}
                    >
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Row>
                          <Col sm={3} className="pr5 pb4">
                            <Nav variant="underline" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="first">
                                  Behavior Counts
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  Frequencies
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="third">Metrics</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="fourth">Counts</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <h4 className="mt-0">Behavior Counts</h4>
                                <BehaviorCounts apiData={role.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <h4 className="mt-0">Frequencies</h4>
                                <Frequencies apiData={role.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                <h4 className="mt-0">Metrics</h4>
                                <Metrics apiData={role.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="fourth">
                                <h4 className="mt-0">Counts</h4>
                                <Counts apiData={role.analytics} />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab>
                  );
                })}
              </Tabs>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Speaker Analytics</Accordion.Header>
            <Accordion.Body className="pa4">
              <Tabs
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
                className="mb-5 "
                fill
              >
                <Tab eventKey="All" title="All">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col sm={3} className="pr5 pb4">
                        <Nav variant="underline" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">
                              Behavior Counts
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">
                              Frequencies
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fourth">Counts</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={9}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <h4 className="mt-0">Behavior Counts</h4>
                            <BehaviorCounts apiData={storedData.speaker_analytics} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <h4 className="mt-0">Frequencies</h4>
                            <Frequencies apiData={storedData.speaker_analytics} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <h4 className="mt-0">Counts</h4>
                            <Counts apiData={storedData.speaker_analytics} />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab>

                {storedData.speaker_analytics.map((speaker, index) => {
                  return (
                    <Tab eventKey={speaker.id} title={speaker.id} key={index}>
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Row>
                          <Col sm={3} className="pr5 pb4">
                            <Nav variant="underline" className="flex-column">
                              <Nav.Item>
                                <Nav.Link eventKey="first">
                                  Behavior Counts
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  Frequencies
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="third">Metrics</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="fourth">Counts</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                          <Col sm={9}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <h4 className="mt-0">Behavior Counts</h4>
                                <BehaviorCounts apiData={speaker.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <h4 className="mt-0">Frequencies</h4>
                                <Frequencies apiData={speaker.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                <h4 className="mt-0">Metrics</h4>
                                <Metrics apiData={speaker.analytics} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="fourth">
                                <h4 className="mt-0">Counts</h4>
                                <Counts apiData={speaker.analytics} />
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </Tab>
                  );
                })}
              </Tabs>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Synchrony Scores</Accordion.Header>
            <Accordion.Body className="pa4">
              <h4 className="mt-0">Synchrony Scores</h4>
              <div className="table-responsive mw5 mw8-ns">
                <Table striped bordered hover >
                  <thead>
                    <tr>
                      <th>Calculation Type</th>
                      <th>Speaker One</th>
                      <th>Speaker Two</th>
                      <th>Score</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storedData.synchrony_scores.map((score, index) => {
                      return (
                        <tr key={index}>
                          <td>{score.calculation_type}</td>
                          <td>{score.identifier_one}</td>
                          <td>{score.identifier_two}</td>
                          <td>
                            {score.synchrony_score === null
                              ? "Too low"
                              : score.synchrony_score}
                          </td>
                          <td>{score.reason}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ): (
        <h3>Please upload a transcript to process. <ArrowRight /></h3>
      )}
    </div>
  );
};

export default Dashboard;
