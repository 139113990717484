import React, { useRef, useState } from "react";

interface IProps {
  item: { title: string; description: string };
}

const AccordionItem: React.FC<IProps> = ({ item }) => {
  const [clicked, setClicked] = useState(false);
  const contentEl = useRef<any>();

  const handleToggle = () => {
    setClicked((prev) => !prev);
  };
  return (
    <div className={`accordion_item ${clicked ? "active" : ""}`}>
      <div className="header" onClick={handleToggle}>
        <span className={`control ${clicked ? "clicked" : ""}`}>{`>`}</span>
        <span>{item.title}</span>
      </div>
      <div
        ref={contentEl}
        className="answer_wrapper"
        style={
          clicked
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="answer">{item.description}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
