import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { chartColors } from "../../helpers/common";

const RadarChartComponent = ({ data, speakers, composite }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" />
        <PolarRadiusAxis />
        <Tooltip />
        {composite ? (
          speakers.map((speaker, index) => (
            <Radar
              key={index}
              name={speaker}
              dataKey={speaker}
              fill={chartColors[index]}
              fillOpacity={0.7}
            />
          ))
        ) : (
          <Radar
            name="Count"
            dataKey="value"
            stroke="#1d8587"
            fill="#67dedf"
            fillOpacity={0.7}
          />
        )}
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default RadarChartComponent;
