import { Rnd } from "react-rnd";
import Divider from '@mui/joy/Divider';
import Card, { CardProps } from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Cloud from '@mui/icons-material/Cloud';
import Sun from '@mui/icons-material/LightMode';

import { unicodeArrow, botRndStyle } from '../helpers/botCommon';

interface BotHighLowsProps {
    data: any;
    position: any;
    size: any;
    title: string;
}

const BotHighLows: React.FC<BotHighLowsProps> = ({ data, position, size, title }) => {

    return (
        <>
            <Rnd
                style={botRndStyle}
                default={{
                    x: position.x,
                    y: position.y,
                    width: size.width,
                    height: size.height,
                }}
            >
                <div style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", textAlign: "center", width: "100%" }}>{title}</div>
                <div>
                    {data.data.analytics?.speaker_analytics?.map((analytics: any) => {
                        return analytics?.highlights?.map((highlight: any, index: any) => {
                            return (
                                <Card variant="outlined" sx={{ width: 400, maxWidth: '100%', gap: 1.5, height: "200px" }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <Typography fontSize="lg" fontWeight="md">
                                                {analytics.id}
                                            </Typography>
                                        </div>
                                        <Chip variant="soft" startDecorator={<Sun htmlColor="#ff00c1" />}>
                                            Highlight
                                        </Chip>
                                    </div>
                                    <Box sx={{ display: 'horizontal' ? 'block' : 'contents' }}>
                                        <Divider />
                                        <Typography level="body-sm">
                                            <Typography fontSize="sm" fontWeight={"bold"}>{highlight.title}</Typography> <br />
                                            {highlight.example}<br />
                                            ({highlight.behaviors.join(unicodeArrow)})
                                        </Typography></Box>
                                </Card>
                            );
                        });
                    })}

                    {data.data.analytics?.speaker_analytics?.map((analytics: any) => {
                        return analytics?.lowlights?.map((highlight: any, index: any) => {
                            return (
                                <Card variant="outlined" sx={{ width: 400, maxWidth: '100%', gap: 1.5, height: "200px" }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>
                                            <Typography fontSize="lg" fontWeight="md">
                                                {analytics.id}
                                            </Typography>
                                        </div>
                                        <Chip variant="soft" startDecorator={<Cloud htmlColor="#67dedf" />}>
                                            Growth Area
                                        </Chip>
                                    </div>
                                    <Box sx={{ display: 'horizontal' ? 'block' : 'contents' }}>
                                        <Typography level="body-sm">
                                            <Typography fontSize="sm" fontWeight={"bold"}>{highlight.title}</Typography> <br />
                                            {highlight.example} <br />
                                            ({highlight.behaviors.join(unicodeArrow)})
                                        </Typography></Box>
                                </Card>
                            );
                        });
                    })}
                </div>
            </Rnd>
        </>
    );
};

export default BotHighLows;