import React, { useState } from "react";
import Button from "../components/base/Button";
import Dashboard from "../components/Dashboard";
import LoadingAnimation from "../components/base/LoadingAnimation";
import { getInsightsAPI } from "../api/api-common";
import { formatString } from "../helpers/sybill";
import { defaultAnalyticsConversation } from "../data/analyicsApi";
const analytics = require("../assets/analytics.png");

const Analytics = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileActive, setFileActive] = useState<boolean>(false);
  const [fileContent, setFileContent] = useState<any>(null);
  const [, setFile] = useState<File | null>(null);
  const [, setInsightActive] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      //If we have a .txt file, assume its a Sybill transcript for now and convert to mpathic json
      const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();
      if (fileExtension == "txt") {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const content = formatString(event.target.result, selectedFile.name);
            setFileContent(content.talk_turns);
            console.log(content.talk_turns);
          }
        };
        reader.readAsText(selectedFile);
      }
      //Original processing of any files (must be mpathic api formatted json technically)
      else {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target) {
            const content = JSON.parse(event.target.result as string);
            setFileContent(content.talk_turns);
          }
        };
        reader.readAsText(selectedFile);
      }
    }
  };

  const handleProcessFile = () => {
    if (fileContent) {
      setFileActive(true);
      handleInsights();
    } else {
      // Handle the case when no file is selected or the file content is not available
      console.error("Please select a file before processing.");
    }
  };

  const handleDefault = () => {
    setFileActive(true);
    setFileContent(defaultAnalyticsConversation.talk_turns)
    setIsLoading(true);
    getInsightsAPI(defaultAnalyticsConversation.talk_turns)
      .then((res) => {
        setInsightActive(true);
        setIsLoading(false);
        setData(res.data.analytics);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err);
        console.log(err);
      });
  }

  const handleInsights = () => {
    setIsLoading(true);
    getInsightsAPI(fileContent)
      .then((res) => {
        setInsightActive(true);
        setIsLoading(false);
        setData(res.data.analytics);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err);
        console.log(err);
      });
  };

  return (
    <>
      <div className=" analytics-container flex flex-row flex-wrap">
        {isLoading && <LoadingAnimation />}

        <div className="left-pane flex flex-row flex-wrap w-75-l">
          <div className="analytics-left w-20-l pa4 ">
            <div className="breadcrumbs f6">
              <a href="/">Home</a> &nbsp; &gt; &nbsp;
              <span>Analytics</span>
            </div>

            <div className="flex flex-column flex-row-m flex-column-l justify-between items-start items-center-m pt4 pv4-l">
              <div>
                <img src={analytics} alt="realtime" />
              </div>
              <div>
                <h3>Analytics</h3>
              </div>
            </div>
          </div>

          <div className="analytics-center w-50-m w-80-l">
            <div className="vh-content-ns overflow-auto pa4 pv5-l pr5-l pl0-l">
              <Dashboard data={data} />
            </div>
          </div>
        </div>

        <div className="right-pane w-25-l bg-black-05">
          <div className="analytics-right w-100 pa4 pa2-m pv3-l pr0-l">
            {fileActive ? (
              <>
                <div
                  className="message-container vh-50 vh-75-ns overflow-auto"
                  contentEditable="false"
                  suppressContentEditableWarning={true}
                >
                  <h5>Transcript Content</h5>
                  <div className="ph2 pr4-l">
                    {fileContent &&
                      fileContent.map((talk: any, i: any) => (
                        <div className="transcript-message pb3" key={i}>
                          <div className="message">
                            <span className="user b">{talk.speaker.id}:</span>
                            <span> {talk.input}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <Button
                  className="mt3"
                  onClick={() => window.location.reload()}
                >
                  Upload another file
                </Button>
              </>
            ) : (
              <div className="pv4 pr4">
                <h4>Upload a transcript to start</h4>
                <input type="file" className="w-90" onChange={handleFileChange} />
                <button onClick={handleProcessFile}>Process File</button>
                <button onClick={handleDefault}>Load Default</button>                
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
