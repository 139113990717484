import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LoginMenu } from './authorization/LoginMenu';
import Logo from '../assets/logo.svg';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  render() {
    return (
      <header className='w-100 fixed top-0 bg-white flex flex-row justify-between pa3 z-5'>
        <div className='flex flex-row justify-start items-center'>
          <Link to='/' className='mr5 logo'><img src={Logo} alt='mpathic' /></Link>
          <Link to='/analytics' className='mr5 b dn db-ns'>Analytics</Link>
          <Link to='/suggestions' className='mr5 b dn db-ns'>Suggestions</Link>
          <Link to='/insights' className='mr5 b dn db-ns'>Insights</Link>
          <Link to='https://mpathic-live.sales.mpathicai.com/' className='mr5 b dn db-ns' target='_blank'>Live</Link>
          <Link to='/classifications' className='mr5 b dn db-ns'>API</Link>
          <Link to='/mpathicbot' className='mr5 b dn db-ns'>Bot</Link>
        </div>
        <div className='tr'>
          <LoginMenu></LoginMenu>
        </div>
      </header>
    );
  }
}
