import React, { useState, ChangeEvent, useEffect } from "react";
import Button from "../components/base/Button";
import MpathicBotDashboard from "../components/MpathicBotDashboard";
import { recallAiCreateBot, recallAiRemoveBot, recallAiBotTranscript, getMpathicConversation, recallAiBotStatus } from "../api/api-common";
import { mpathicAnalyticsSample } from "../data/analyticsSample";
import { formatString } from "../helpers/botCommon"
import Chip from "@mui/joy/Chip";

const MpathicBot = () => {
    const [gMeeting, setGMeeting] = useState('');
    const [botId, setBotId] = useState<string>('');
    const [isBotInMeeting, setIsBotInMeeting] = useState<boolean>(false);
    const [apiResponse, setApiResponse] = useState<any>([]);
    const [botState, setBotState] = useState<string>('');

    useEffect(() => {
        const isSampleView = new URLSearchParams(window.location.search).get('sample');
        if (isSampleView !== undefined && isSampleView !== null) { setApiResponse(mpathicAnalyticsSample) };
        const callAPI = () => {
            if (botId !== '') {
            console.log('Calling rev for transcript.');
            recallAiBotTranscript(botId)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.length > 0) {
                        const translatedData = {
                            conversation_id: `recallai_${botId}`,
                            include_conversation: false,
                            talk_turns: res.data.map(({ speaker, words }: { speaker: string, words: { text: string }[] }) => ({
                                speaker: { id: speaker },
                                input: words.map(({ text }) => text).join(" ")
                            }))
                        };
                        if (translatedData.talk_turns.length > 0) {
                            console.log('Calling mpathic api.');
                            getMpathicConversation(translatedData)
                                .then((res) => {
                                    console.log(res?.data);
                                    setApiResponse(res?.data);
                                });
                        }
                    }
                })
                console.log(botState);
                console.log(isBotInMeeting);
            }
        };
        if (isBotInMeeting) {
            const apiInterval = setInterval(callAPI, 5000);
            return () => {
                clearInterval(apiInterval);
            };
        }
    }, [isBotInMeeting]);

    useEffect(() => {
        const checkBotStatus = () => {
            if (botId !== '') {
                recallAiBotStatus(botId)
                    .then((res => {
                        setBotState(res.data.status_changes[res.data.status_changes.length - 1].code)
                    }));
            }
        };

        if (botState !== "done" && botId != '') {
            const botInterval = setInterval(checkBotStatus, 10000);
            return () => {
                clearInterval(botInterval);
            }
        }
    }, [isBotInMeeting]);

    useEffect(() => {
        if (botState === 'done') {
          setIsBotInMeeting(false);
        }
      }, [botState]);

    const handleMeetingChange = (event: ChangeEvent<HTMLInputElement>) => {
        setGMeeting(event.target.value);
    };

    const handleBotJoinClick = () => {
        setApiResponse('');
        setBotId('');
        setBotState('');
        recallAiCreateBot(gMeeting)
            .then((res) => {
                setBotId(res.data.id);
                setIsBotInMeeting(true);
            });
    };

    const handleBotLeaveClick = () => {
        recallAiRemoveBot(botId)
            .then((res) => {
                setIsBotInMeeting(false);
            })
    };

    return (
        <>
            <div className="bot-container">
                <div className="bot-center center pa4" style={{ maxWidth: "2000px" }}>
                    <div className="breadcrumbs f6 mb4">
                        <a href="/">Home</a> &nbsp; &gt; &nbsp;
                        <span>Bot</span>
                    </div>
                    <div className="vh-75 overflow-auto bg-white br4 box-shadow">
                        <div className="correction-window">
                            <div className="correction-window-body">
                                <div className="correction-wrapper">
                                    <div className="input-wrapper">
                                        <div className="flex flex-row justify-between items-center">
                                            <div className="flex flex-row items-center">
                                                <h3 className="mr4">Dashboard</h3>
                                                <input type="text" className="mr3" value={gMeeting} size={40} onChange={handleMeetingChange} placeholder="Enter Google Meeting Link" />
                                                {!isBotInMeeting && (botState === 'done' || botState === '') 
                                                    ? (<Button className="insight-home-btn" onClick={handleBotJoinClick} disabled={gMeeting===''}>Join</Button>)
                                                    : (<Button className="insight-home-btn" onClick={handleBotLeaveClick}>Leave</Button>)
                                                }
                                            </div>
                                            <div>
                                                {isBotInMeeting && (<Chip>Bot {botId} is {formatString(botState)}</Chip>)}  
                                            </div>
                                        </div>
                                        <div>
                                            <MpathicBotDashboard data={apiResponse} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default MpathicBot;
