import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import AuthorizeRoute from './components/authorization/AuthorizeRoute';
import { Layout } from './components/Layout';
import { ParallaxProvider } from 'react-scroll-parallax';
import "./styles/app.scss";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <ParallaxProvider>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, requireAuth, ...rest } = route;
              return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
            })}
          </Routes>
        </ParallaxProvider>
      </Layout>
    );
  }
}
