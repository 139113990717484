import axios from "axios";

export async function getSuggestionsAPI(message_data: string) {
  const data = JSON.stringify({
    input: message_data,
    include_suggestions: true
  })
  var config = {
    method: 'POST',
    url: "mpathicApi/v1/behaviors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, X-Requested-With",
    },
    data: data,
    timeout: 0,
  };
  return axios(config);
}

export async function getInsightsAPI(talk_turns: any) {
  const data = JSON.stringify({
    "talk_turns": talk_turns
  });
  var config = {
    method: 'POST',
    url: "mpathicApi/v1/conversations",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: data,
    timeout: 0,
  };
  return axios(config);
}

export async function getChatCompletionAPI(chat: any) {
  const data = JSON.stringify({
    model: "gpt-4",
    temperature: 0.7,
    frequency_penalty: 0,
    max_tokens: 2048,
    messages: chat
  });
  var config = {
    method: 'POST',
    url: "api/chatCompletion",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    data: data,
    timeout: 0,
  };
  return axios(config);
}

export async function getClassificationsAPI(message_data: string) {
  const data = JSON.stringify({
    input: message_data,
    include_suggestions: true,
    include_behaviors: true
  })
  var config = {
    method: 'POST',
    url: "mpathicApi/v1/classifications",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, X-Requested-With",
    },
    data: data,
    timeout: 0,
  };
  return axios(config);
}

export async function recallAiCreateBot(meeting_url: string) {
  const data = JSON.stringify({
    meeting_url: meeting_url,
    bot_name: "mpathic Bot",
    transcription_options: {
      provider: "rev"
    }
  });
  var config = {
    method: 'POST',
    url: "recallai/api/v1/bot/",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
    timeout: 0,
  };
  return axios(config);
}

export async function recallAiBotTranscript(bot_id: string) {
  var config = {
    method: 'GET',
    url: `recallai/api/v1/bot/${bot_id}/transcript`,
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 0,
  };
  return axios(config);
}

export async function recallAiRemoveBot(bot_id: string) {
  var config = {
    method: 'POST',
    url: `recallai/api/v1/bot/${bot_id}/leave_call`,
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 0,
  };
  return axios(config);
}

export async function recallAiBotStatus(bot_id: string) {
  var config = {
    method: 'GET',
    url: `recallai/api/v1/bot/${bot_id}/`,
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 0,
  };
  return axios(config);
}

export async function getMpathicConversation(payload: any) {
    var config = {
      method: 'POST',
      url: "mpathicApi/v1/conversations",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: payload,
      timeout: 0,
    };
    return axios(config);
}
