import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import highchartsMore from "highcharts/highcharts-more.js"
import item from "highcharts/modules/item-series";



highchartsMore(Highcharts);
item(Highcharts);

require('highcharts/modules/exporting')(Highcharts);

Highcharts.setOptions({
    colors: ['#ff00c1', '#67dedf', '#fcd703', '#000']
  });

interface ColumnChartProps {
    data: any
}

const ColumnChart: React.FC<ColumnChartProps> = ({data}) => {
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={ {
            chart: {
                type: 'column',
                width: 650
            },
            title: {
              text: 'Model Responses'
            },
            xAxis: {
                categories: data.categories,
                crosshair: true,
                accessibility: {
                    description: 'Countries'
                }
            },
            series: data.series,
            credits: {
                enabled: false
            }
          }}
      />
    </div>
  );
};

export default ColumnChart;