import BarChartComponent from "./Charts/BarChart";
import Table from "react-bootstrap/Table";
import { renderTable, mergeTableData, mergeChartData, convertAllNumbers } from "../helpers/common";

const Frequencies = ({ apiData }) => {
    let barChartData = [];
    let tableData;
    let speakerNames = [];
    let composite = false;
    
    if (apiData.behavior_counts !== undefined) {
      tableData = convertAllNumbers(apiData.frequencies);
      barChartData = Object.entries(tableData).map(([name, value]) => ({
        name,
        value,
      }));
    } else {
      composite = true;
      barChartData = mergeChartData(apiData, "frequencies");
      tableData = mergeTableData(apiData, "frequencies");
      speakerNames = apiData.map((speaker) => speaker.id);
    }
  
    return (
      <>
        <BarChartComponent data={barChartData} speakers={speakerNames} composite={composite} />
        <Table striped bordered hover size="sm" className="w-75 mx-auto">
          <thead>
            <tr>
              <th>Behavior</th>
              <th className="text-center">Frequency (%)</th>
            </tr>
          </thead>
          <tbody>{renderTable(tableData)}</tbody>
        </Table>
      </>
    );
  };
  
export default Frequencies;
  