import React, { Component } from 'react';

export class Conversations extends Component {
  static displayName = Conversations.name;

  constructor(props) {
    super(props);
    this.state = { analytics: [], loading: true };
  }

  componentDidMount() {
    this.fetchConversations();
  }

  static renderConversationAnalytics(analytics) {
    return (
      <div>
        <pre>
          {JSON.stringify(analytics, null, 2)}
        </pre>
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Conversations.renderConversationAnalytics(this.state.analytics);

    return (
      <div>
        <h1 id="tableLabel">Conversations</h1>
        <p>This component demonstrates fetching conversation analytics from the mpathic API.</p>
        {contents}
      </div>
    );
  }

  async fetchConversations() {
    const rawResponse = await fetch('mpathicApi/v1/conversations', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "conversation_id": "1234",
        "talk_turns": [
          {
            "input": "Hello Digby. How are you doing today?",
            "speaker": {
              "id": "Juniper",
              "role": "Therapist"
            }
          },
          {
            "input": "It's been a long week, to be honest.",
            "speaker": {
              "id": "Digby",
              "role": "Patient"
            }
          }
        ]
      })
    });
    const content = await rawResponse.json();
    this.setState({ analytics: content, loading: false });
  }
}
