export function formatString(input, file_name) {
    const regex = /\[(\d{2}:\d{2})\] (.+?):(.+)/g;
    const talkTurns = [];
    
    let match;
    while (match = regex.exec(input)) {
      const timestamp = match[1];
      const speaker = match[2].trim();
      const talk = match[3].trim();
      
      const talkTurn = {
        input: talk,
        speaker: {
          id: speaker
        }
      };
      
      talkTurns.push(talkTurn);
    }
    console.log(JSON.stringify({"conversation_id": file_name, "include_conversation": true, "talk_turns": talkTurns }));
    return {"conversation_id": file_name, "include_conversation": true, "talk_turns": talkTurns };
  }
  