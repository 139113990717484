export const mpathicAnalyticsSample = {
    "id": "00-272f1b4f95886c4b4834306e7d547c62-6b828d90addaef5c-00",
    "created_at": 1708010280,
    "conversation_id": "recallai_1347bd96-7b02-43f5-8b0a-8736aa97b0de",
    "analytics": {
        "speaker_analytics": [
            {
                "id": "Brian Williams",
                "role": null,
                "analytics": {
                    "utterance_count": 243,
                    "talk_turn_count": 58,
                    "behavior_counts": {
                        "appreciation": 4,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 3,
                        "instruct": 0,
                        "low_curiosity": 6,
                        "high_curiosity": 5,
                        "preparation": 0,
                        "reflective_listening": 2
                    },
                    "frequencies": {
                        "appreciation": 0.016460905,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 0.012345679,
                        "instruct": 0,
                        "low_curiosity": 0.024691358,
                        "high_curiosity": 0.020576132,
                        "preparation": 0,
                        "reflective_listening": 0.008230452
                    },
                    "metrics": {
                        "collaboration": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "reflective_listening",
                                    "count": 2,
                                    "weight_on_score": 40,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 3,
                                    "weight_on_score": 60.000004,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4
                        },
                        "curiosity": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "high_curiosity",
                                    "count": 5,
                                    "weight_on_score": 45.454548,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 6,
                                    "weight_on_score": 54.545456,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4.5
                        },
                        "empathy": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "appreciation",
                                    "count": 4,
                                    "weight_on_score": 20,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "high_curiosity",
                                    "count": 5,
                                    "weight_on_score": 25,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "reflective_listening",
                                    "count": 2,
                                    "weight_on_score": 10,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 3,
                                    "weight_on_score": 15,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 6,
                                    "weight_on_score": 30,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 5.5
                        }
                    }
                },
                "highlights": [
                    {
                        "id": "77F34D98-B590-443B-B932-A588C91DE9FA",
                        "title": "Question followed by giving information/advice/direction followed by question",
                        "example": "What is going on at work? Please tell me more. Are you thinking of leaving?",
                        "behaviors": [
                            "high_curiosity",
                            "instruct_direction",
                            "low_curiosity"
                        ],
                        "talk_turns": [
                            {
                                "index": 5,
                                "talk_turn_id": "d210469b-84a0-4755-9ecc-c2e00a4b2cab",
                                "start_time": "2023-08-02T18:05:38.1701476+00:00",
                                "end_time": "2023-08-02T18:06:38.1701476+00:00"
                            }
                        ]
                    }
                ],
                "lowlights": [
                    {
                        "id": "EDCD61E7-AEB9-469D-B257-0A6E1DB45B26",
                        "title": "Four questions in a row without a response",
                        "example": "Can you tell me more? Are you doing ok? Can you describe how you are feeling? How is your day?",
                        "behaviors": [
                            "high_curiosity",
                            "low_curiosity"
                        ],
                        "talk_turns": [
                            {
                                "index": 2,
                                "talk_turn_id": "0a2537f6-b034-4a9e-93f7-181f3c13fe60",
                                "start_time": "2023-08-02T18:04:38.1701476+00:00",
                                "end_time": "2023-08-02T18:05:38.1701476+00:00"
                            }
                        ]
                    }
                ]
            },
            {
                "id": "Matt Anderson",
                "role": null,
                "analytics": {
                    "utterance_count": 172,
                    "talk_turn_count": 57,
                    "behavior_counts": {
                        "appreciation": 4,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 3,
                        "instruct": 1,
                        "low_curiosity": 7,
                        "high_curiosity": 5,
                        "preparation": 0,
                        "reflective_listening": 3
                    },
                    "frequencies": {
                        "appreciation": 0.023255814,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 0.017441861,
                        "instruct": 0.0058139535,
                        "low_curiosity": 0.040697675,
                        "high_curiosity": 0.029069768,
                        "preparation": 0,
                        "reflective_listening": 0.017441861
                    },
                    "metrics": {
                        "collaboration": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "reflective_listening",
                                    "count": 3,
                                    "weight_on_score": 42.857143,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 3,
                                    "weight_on_score": 42.857147,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "instruct",
                                    "count": 1,
                                    "weight_on_score": 14.285715,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4.3
                        },
                        "curiosity": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "high_curiosity",
                                    "count": 5,
                                    "weight_on_score": 41.666664,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 7,
                                    "weight_on_score": 58.333332,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4.2
                        },
                        "empathy": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "appreciation",
                                    "count": 4,
                                    "weight_on_score": 17.391304,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "high_curiosity",
                                    "count": 5,
                                    "weight_on_score": 21.73913,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "reflective_listening",
                                    "count": 3,
                                    "weight_on_score": 13.043478,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 3,
                                    "weight_on_score": 13.043479,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "instruct",
                                    "count": 1,
                                    "weight_on_score": 4.3478265,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 7,
                                    "weight_on_score": 30.434782,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 5.2
                        }
                    }
                }
            }
        ],
        "role_analytics": [
            {
                "role": null,
                "analytics": {
                    "utterance_count": 415,
                    "talk_turn_count": 115,
                    "behavior_counts": {
                        "appreciation": 8,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 6,
                        "instruct": 1,
                        "low_curiosity": 13,
                        "high_curiosity": 10,
                        "preparation": 0,
                        "reflective_listening": 5
                    },
                    "frequencies": {
                        "appreciation": 0.019277109,
                        "conflict": 0,
                        "confront": 0,
                        "instruct_direction": 0.014457831,
                        "instruct": 0.0024096386,
                        "low_curiosity": 0.031325303,
                        "high_curiosity": 0.024096385,
                        "preparation": 0,
                        "reflective_listening": 0.012048192
                    },
                    "metrics": {
                        "collaboration": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "reflective_listening",
                                    "count": 5,
                                    "weight_on_score": 41.666664,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 6,
                                    "weight_on_score": 50,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "instruct",
                                    "count": 1,
                                    "weight_on_score": 8.333334,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4.2
                        },
                        "curiosity": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "high_curiosity",
                                    "count": 10,
                                    "weight_on_score": 43.47826,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 13,
                                    "weight_on_score": 56.521736,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 4.3
                        },
                        "empathy": {
                            "contributing_behaviors": [
                                {
                                    "behavior": "appreciation",
                                    "count": 8,
                                    "weight_on_score": 18.604652,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "high_curiosity",
                                    "count": 10,
                                    "weight_on_score": 23.255814,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "reflective_listening",
                                    "count": 5,
                                    "weight_on_score": 11.627907,
                                    "influence_on_score": "positive"
                                },
                                {
                                    "behavior": "instruct_direction",
                                    "count": 6,
                                    "weight_on_score": 13.95349,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "instruct",
                                    "count": 1,
                                    "weight_on_score": 2.3255816,
                                    "influence_on_score": "negative"
                                },
                                {
                                    "behavior": "low_curiosity",
                                    "count": 13,
                                    "weight_on_score": 30.23256,
                                    "influence_on_score": "negative"
                                }
                            ],
                            "score": 5.3
                        }
                    }
                }
            }
        ],
        "conversation_analytics": {
            "utterance_count": 415,
            "talk_turn_count": 115,
            "behavior_counts": {
                "appreciation": 8,
                "conflict": 0,
                "confront": 0,
                "instruct_direction": 6,
                "instruct": 1,
                "low_curiosity": 13,
                "high_curiosity": 10,
                "preparation": 0,
                "reflective_listening": 5
            },
            "frequencies": {
                "appreciation": 0.019277109,
                "conflict": 0,
                "confront": 0,
                "instruct_direction": 0.014457831,
                "instruct": 0.0024096386,
                "low_curiosity": 0.031325303,
                "high_curiosity": 0.024096385,
                "preparation": 0,
                "reflective_listening": 0.012048192
            },
            "metrics": {
                "collaboration": {
                    "contributing_behaviors": [
                        {
                            "behavior": "reflective_listening",
                            "count": 5,
                            "weight_on_score": 41.666664,
                            "influence_on_score": "positive"
                        },
                        {
                            "behavior": "instruct_direction",
                            "count": 6,
                            "weight_on_score": 50,
                            "influence_on_score": "negative"
                        },
                        {
                            "behavior": "instruct",
                            "count": 1,
                            "weight_on_score": 8.333334,
                            "influence_on_score": "negative"
                        }
                    ],
                    "score": 4.2
                },
                "curiosity": {
                    "contributing_behaviors": [
                        {
                            "behavior": "high_curiosity",
                            "count": 10,
                            "weight_on_score": 43.47826,
                            "influence_on_score": "positive"
                        },
                        {
                            "behavior": "low_curiosity",
                            "count": 13,
                            "weight_on_score": 56.521736,
                            "influence_on_score": "negative"
                        }
                    ],
                    "score": 4.3
                },
                "empathy": {
                    "contributing_behaviors": [
                        {
                            "behavior": "appreciation",
                            "count": 8,
                            "weight_on_score": 18.604652,
                            "influence_on_score": "positive"
                        },
                        {
                            "behavior": "high_curiosity",
                            "count": 10,
                            "weight_on_score": 23.255814,
                            "influence_on_score": "positive"
                        },
                        {
                            "behavior": "reflective_listening",
                            "count": 5,
                            "weight_on_score": 11.627907,
                            "influence_on_score": "positive"
                        },
                        {
                            "behavior": "instruct_direction",
                            "count": 6,
                            "weight_on_score": 13.95349,
                            "influence_on_score": "negative"
                        },
                        {
                            "behavior": "instruct",
                            "count": 1,
                            "weight_on_score": 2.3255816,
                            "influence_on_score": "negative"
                        },
                        {
                            "behavior": "low_curiosity",
                            "count": 13,
                            "weight_on_score": 30.23256,
                            "influence_on_score": "negative"
                        }
                    ],
                    "score": 5.3
                }
            }
        },
        "synchrony_scores": [
            {
                "calculation_type": "speaker",
                "identifier_one": "Brian Williams",
                "identifier_two": "Matt Anderson",
                "synchrony_score": 1.1,
                "reason": null
            },
            {
                "calculation_type": "role",
                "identifier_one": "",
                "identifier_two": "",
                "synchrony_score": null,
                "reason": "There are less than 2 roles in the conversation"
            }
        ]
    },
    "api_version": "2023-06-30"
}