import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { chartColors } from "../../helpers/common";

const BarChartComponent = ({ data, speakers, composite }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 50,
          right: 30,
          left: 20,
          bottom: 100,
        }}
        className="mx-auto"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          textAnchor="end"
          angle={-40}
        />
        <YAxis />
        <Tooltip />
        {composite ? (
            speakers.map((speaker, index) => (
              <Bar
                key={index}
                name={speaker}
                dataKey={speaker}
                stackId="frequencies"
                fill={chartColors[index]}
              />
              ))
        ) : (
          <Bar
            dataKey="value"
            fill="#67dedf"
            activeBar={
              <Rectangle
                fill="#bcf0f1"
                stroke="#67dedf"
              />
            }
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
