export const healthcareHighlights = [
  {
    title: "Autonomy",
    description: `It's up to you whether you would like to continue on these medications.`,
  },
  {
    title: "Appreciation",
    description: `You have worked really hard to push through.`,
  },
  {
    title: "Reflective Listening",
    description: `It sounds like you have a lot of motivation to keep with it, side effects and all. Tell me 
    more about that.`,
  },
];

export const healthcareLowlights = [
  {
    title: "Instruct (Direction)",
    description: `You need to prioritize this if you want to see more change. `,
  },
  {
    title: "Confront",
    description: `You aren't acting like it is important to you when you don't follow your prescription.`,
  },
  {
    title: "Confront",
    description: `I do think you make irresponsible decisions with your health.`,
  },
  {
    title: "Curiosity",
    description: `Is that still your plan?`,
  },
];

export const healthcareTalkTurns = [
  {
    input: "Good morning Jordan. How are you doing today?" ,
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `I’m doing okay. We just got back from a trip to see my family. I’ve been playing alot of catch up at work.`,
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: "It sounds like you’ve been busy. How have you been feeling?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:00:11", end: "00:00:23" },
  },
  {
    input: "I’m really stressed. I can’t seem to relax.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:00:25", end: "00:01:10" },
  },
  {
    input: "You look upset. Tell me what’s going on.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:01:11", end: "00:01:23" },
  },
  {
    input: "My headaches are back and they make it impossible to focus on anything or get things done. I finish with work and then I’m too tired to do anything else.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:01:25", end: "00:02:10" },
  },
  {
    input: "Have you been keeping up with your running?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:02:10", end: "00:02:23" },
  },
  {
    input: "No. I haven’t been to the gym in the last few weeks either. I’m too exhausted.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:02:25", end: "00:03:19" },
  },
  {
    input: "We’ve talked about this before. Do you really think staying home on your couch is going to give you more energy?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:03:30", end: "00:04:03" },
  },
  {
    input: "I know, I am trying my best but I can’t get myself to do anything.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:04:05", end: "00:05:10" },
  },
  {
    input: "You have worked really hard to push through. The last time we met you mentioned that running was a great stress reliever for you.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:05:10", end: "00:06:03" },
  },
  {
    input: "Yeah, it helps a lot when I can get myself to do it." ,
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:06:05", end: "00:07:21" },
  },
  {
    input: "What has helped you get motivated to run in the past when you were feeling pretty stressed and tired?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:07:21", end: "00:08:03" },
  },
  {
    input: "I don’t know. I guess it helped that I would run in the morning before work instead of waiting until the end of the day. I didn’t even need coffee after my runs, they gave me enough energy on their own.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:08:05", end: "00:09:10" },
  },
  {
    input: "Sounds like it gave you a big boost. Did you notice any other benefits?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:09:20", end: "00:10:03" },
  },
  {
    input: "My mood was always better, which helped with work and at home too.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:08:05", end: "00:09:10" },
  },
  {
    input: "You noticed a big difference when you were taking care of yourself in that way. You should remind yourself of that when you don’t want to wake up in the morning. ",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:09:20", end: "00:10:03" },
  },
  {
    input: "Yeah, I’ll try to.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:09:45", end: "00:10:10" },
  },
  {
    input: "You need to prioritize this if you want to see more change. You can’t expect the medication to take care of everything. Speaking of the medication, how are you feeling on the new doses?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:10:20", end: "00:10:53" },
  },
  {
    input: "I have been feeling a little dizzy and nauseated in the mornings since we increased everything.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:11:05", end: "00:12:10" },
  },
  {
    input: "That sounds difficult. What time of day are you taking them?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:12:20", end: "00:13:03" },
  },
  {
    input: "Usually in the morning.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:13:05", end: "00:14:12" },
  },
  {
    input: "You could try taking them before bed and see if that helps those side effects.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:14:20", end: "00:15:03" },
  },
  {
    input: "I’ll give that a try I guess. Will the side effects go away eventually?",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:15:05", end: "00:16:10" },
  },
  {
    input: "Side effects resolve for most people within about 6-8 weeks. Are you taking them every day?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Honestly sometimes I skip them to give myself a break from nausea and dizziness.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "It’s up to you whether you would like to continue on these medications. However, you should give yourself time to think about it before making a decision.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "I do want to feel better, and it is really important to me.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:13:05", end: "00:14:12" },
  },
  {
    input: "You aren’t acting like it is important to you when you don’t follow your prescription. I’m hearing that the negative effects feel like they may outweigh the positives at times.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Yeah, I guess that’s true. But I also know without the medication the effects could be a lot worse.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "It seems like you are afraid of the consequences of stopping the medication completely. What is keeping you on it right now?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "You mentioned before that without the medication my condition would worsen pretty rapidly. I don’t want that to happen. I’m not ready for that. ",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "It sounds like you have a lot of motivation to keep with it, side effects and all. Tell me more about that.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Last time you mentioned those same reasons for quitting smoking and said you were ready to cut back. Is that still your plan?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: " I think so, eventually. I’m not sure now is the right time with all of the other stress I’ve got going on.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "It is your decision if you want to quit and when that will be.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: " Why would you say that when I know you don’t believe it? You are obviously lying and have an opinion about it.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "I do think you make irresponsible decisions with your health. You need to start following through if you say these things are important to you.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "How am I supposed to make all of these changes at once?",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "You could start with one step at a time. We have some helpful brochures in the lobby. You could grab some for reference on your way out.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Sure. I’ve heard about patches that might help me quit. Could you help me get some of those?",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "You feel like the patches would help ease the stress of quitting. It sounds like a part of you is really ready to make that change.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "If I could get some help with it, I’m willing to give it a shot.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "You’ve pushed through some really difficult times in the past, and I’m confident you can do it again. You just need to stick with it.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: " I know, I know. The last time I tried to quit when I was already going through stress felt like too much. Maybe the patches would help me stick with it. Do you think they make a difference?",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "I think it is worth a try. I’ll write out the prescription before you leave. Make sure you remind me.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "I appreciate that.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "What else would you like to cover before we end our visit today?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "I think that’s it for now.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "Are you committed to continuing with your medication for now? Maybe you could try in the evenings as we discussed. ",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Yes, I already told you I would try it. I’ll set a reminder on my phone as soon as I leave.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "I understand it is difficult with all of the other stress you have going on right now. I appreciate you taking the time to come discuss your concerns with me today.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "Yeah, thanks for squeezing me in.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "By the way, you should give us more notice the next time you need to cancel an appointment.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "It’s not like I can plan when I will get sick. I gave as much notice as I could. You should understand that better than anyone.",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "We need to wrap this up. Next time we will go over the results of your blood draw.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "I’ll grab your prescription on our way out. I want you to come back in two weeks so we can see how things are going. How does that sound?",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
  {
    input: "That works for me. ",
    speaker: { id: "Jordan", role: "Patient" },
    timestamp: { start: "00:17:05", end: "00:18:10" },
  },
  {
    input: "Great. I’ll see you then. Have a good day.",
    speaker: { id: "Dr. Howard", role: "Doctor" },
    timestamp: { start: "00:16:20", end: "00:17:03" },
  },
];

export const InsightsHealtcareApi = [
  {
    title: "# request",
    apiEndPoint: `POST`,
    code: JSON.parse(`{
      "talk_turns": [
        {
          "input": "Good morning Jordan. How are you doing today?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "I’m doing okay. We just got back from a trip to see my family. I’ve been playing alot of catch up at work.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "It sounds like you’ve been busy. How have you been feeling?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:00:11",
            "end": "00:00:23"
          }
        },
        {
          "input": "I’m really stressed. I can’t seem to relax.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:25",
            "end": "00:01:10"
          }
        },
        {
          "input": "You look upset. Tell me what’s going on.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:01:11",
            "end": "00:01:23"
          }
        },
        {
          "input": "My headaches are back and they make it impossible to focus on anything or get things done. I finish with work and then I’m too tired to do anything else.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:01:25",
            "end": "00:02:10"
          }
        },
        {
          "input": "Have you been keeping up with your running?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:02:10",
            "end": "00:02:23"
          }
        },
        {
          "input": "No. I haven’t been to the gym in the last few weeks either. I’m too exhausted.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:02:25",
            "end": "00:03:19"
          }
        },
        {
          "input": "We’ve talked about this before. Do you really think staying home on your couch is going to give you more energy?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:03:30",
            "end": "00:04:03"
          }
        },
        {
          "input": "I know, I am trying my best but I can’t get myself to do anything.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:04:05",
            "end": "00:05:10"
          }
        },
        {
          "input": "You have worked really hard to push through. The last time we met you mentioned that running was a great stress reliever for you.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:05:10",
            "end": "00:06:03"
          }
        },
        {
          "input": "Yeah, it helps a lot when I can get myself to do it.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:06:05",
            "end": "00:07:21"
          }
        },
        {
          "input": "What has helped you get motivated to run in the past when you were feeling pretty stressed and tired?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:07:21",
            "end": "00:08:03"
          }
        },
        {
          "input": "I don’t know. I guess it helped that I would run in the morning before work instead of waiting until the end of the day. I didn’t even need coffee after my runs, they gave me enough energy on their own.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:08:05",
            "end": "00:09:10"
          }
        },
        {
          "input": "Sounds like it gave you a big boost. Did you notice any other benefits?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:09:20",
            "end": "00:10:03"
          }
        },
        {
          "input": "My mood was always better, which helped with work and at home too.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:08:05",
            "end": "00:09:10"
          }
        },
        {
          "input": "You noticed a big difference when you were taking care of yourself in that way. You should remind yourself of that when you don’t want to wake up in the morning. ",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:09:20",
            "end": "00:10:03"
          }
        },
        {
          "input": "Yeah, I’ll try to.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:09:45",
            "end": "00:10:10"
          }
        },
        {
          "input": "You need to prioritize this if you want to see more change. You can’t expect the medication to take care of everything. Speaking of the medication, how are you feeling on the new doses?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:10:20",
            "end": "00:10:53"
          }
        },
        {
          "input": "I have been feeling a little dizzy and nauseated in the mornings since we increased everything.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:11:05",
            "end": "00:12:10"
          }
        },
        {
          "input": "That sounds difficult. What time of day are you taking them?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:12:20",
            "end": "00:13:03"
          }
        },
        {
          "input": "Usually in the morning.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:13:05",
            "end": "00:14:12"
          }
        },
        {
          "input": "You could try taking them before bed and see if that helps those side effects.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:14:20",
            "end": "00:15:03"
          }
        },
        {
          "input": "I’ll give that a try I guess. Will the side effects go away eventually?",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:15:05",
            "end": "00:16:10"
          }
        },
        {
          "input": "Side effects resolve for most people within about 6-8 weeks. Are you taking them every day?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Honestly sometimes I skip them to give myself a break from nausea and dizziness.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "It’s up to you whether you would like to continue on these medications. However, you should give yourself time to think about it before making a decision.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "I do want to feel better, and it is really important to me.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:13:05",
            "end": "00:14:12"
          }
        },
        {
          "input": "You aren’t acting like it is important to you when you don’t follow your prescription. I’m hearing that the negative effects feel like they may outweigh the positives at times.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Yeah, I guess that’s true. But I also know without the medication the effects could be a lot worse.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "It seems like you are afraid of the consequences of stopping the medication completely. What is keeping you on it right now?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "You mentioned before that without the medication my condition would worsen pretty rapidly. I don’t want that to happen. I’m not ready for that. ",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "It sounds like you have a lot of motivation to keep with it, side effects and all. Tell me more about that.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Last time you mentioned those same reasons for quitting smoking and said you were ready to cut back. Is that still your plan?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": " I think so, eventually. I’m not sure now is the right time with all of the other stress I’ve got going on.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "It is your decision if you want to quit and when that will be.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": " Why would you say that when I know you don’t believe it? You are obviously lying and have an opinion about it.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "I do think you make irresponsible decisions with your health. You need to start following through if you say these things are important to you.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "How am I supposed to make all of these changes at once?",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "You could start with one step at a time. We have some helpful brochures in the lobby. You could grab some for reference on your way out.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Sure. I’ve heard about patches that might help me quit. Could you help me get some of those?",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "You feel like the patches would help ease the stress of quitting. It sounds like a part of you is really ready to make that change.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "If I could get some help with it, I’m willing to give it a shot.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "You’ve pushed through some really difficult times in the past, and I’m confident you can do it again. You just need to stick with it.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": " I know, I know. The last time I tried to quit when I was already going through stress felt like too much. Maybe the patches would help me stick with it. Do you think they make a difference?",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "I think it is worth a try. I’ll write out the prescription before you leave. Make sure you remind me.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "I appreciate that.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "What else would you like to cover before we end our visit today?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "I think that’s it for now.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "Are you committed to continuing with your medication for now? Maybe you could try in the evenings as we discussed. ",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Yes, I already told you I would try it. I’ll set a reminder on my phone as soon as I leave.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "I understand it is difficult with all of the other stress you have going on right now. I appreciate you taking the time to come discuss your concerns with me today.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "Yeah, thanks for squeezing me in.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "By the way, you should give us more notice the next time you need to cancel an appointment.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "It’s not like I can plan when I will get sick. I gave as much notice as I could. You should understand that better than anyone.",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "We need to wrap this up. Next time we will go over the results of your blood draw.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "I’ll grab your prescription on our way out. I want you to come back in two weeks so we can see how things are going. How does that sound?",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        },
        {
          "input": "That works for me. ",
          "speaker": {
            "id": "Jordan",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:17:05",
            "end": "00:18:10"
          }
        },
        {
          "input": "Great. I’ll see you then. Have a good day.",
          "speaker": {
            "id": "Dr. Howard",
            "role": "Doctor"
          },
          "timestamp": {
            "start": "00:16:20",
            "end": "00:17:03"
          }
        }
      ]
    }`),
  },
  {
    title: "# response",
    code: JSON.parse(`{
      "conversation": {
          "sentence_count": 113,
          "talk_turn_count": 61,
          "frequencies": {
              "strength_based": 0.044,
              "toxicity": 0
          },
          "metrics": {
              "empathy": 5.8,
              "collaboration": 7.5,
              "curiosity": 4.7,
              "synchrony": -1
          }
      },
      "speakers": [
          {
              "id": "Dr. Howard",
              "role": "Therapist",
              "sentence_count": 4,
              "talk_turn_count": 2,
              "frequencies": {
                  "strength_based": 0,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 10,
                  "collaboration": 10,
                  "curiosity": -1
              }
          },
          {
              "id": "Jordan",
              "role": "Patient",
              "sentence_count": 54,
              "talk_turn_count": 31,
              "frequencies": {
                  "strength_based": 0.056,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 4.2,
                  "collaboration": 5,
                  "curiosity": 2.5
              }
          },
          {
              "id": "Dr. Howard",
              "role": "Patient",
              "sentence_count": 48,
              "talk_turn_count": 25,
              "frequencies": {
                  "strength_based": 0.042,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 6.7,
                  "collaboration": 7.5,
                  "curiosity": 6.4
              }
          },
          {
              "id": "Dr. Howard",
              "role": "Patient",
              "sentence_count": 2,
              "talk_turn_count": 1,
              "frequencies": {
                  "strength_based": 0,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 0,
                  "collaboration": -1,
                  "curiosity": 0
              }
          },
          {
              "id": "Dr. Howard",
              "role": "Patient",
              "sentence_count": 5,
              "talk_turn_count": 2,
              "frequencies": {
                  "strength_based": 0,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 5,
                  "collaboration": 10,
                  "curiosity": 0
              }
          }
      ]
  }`),
  },
];

// 1:1

export const oneToOneHighlights = [
  {
    title: "Autonomy",
    description: `And I should add that you do not have to answer if you do not feel comfortable.`,
  },
  {
    title: "Reflective Listening",
    description: ` It sounds like your team has really helped you.`,
  },
  {
    title: "Appreciation",
    description: `Otherwise, you are already showing that you are a great asset to the team.`,
  },
];

export const oneToOneLowlights = [
  {
    title: "Confront",
    description: `You haven't been doing that as much as I would like and it's a concern.`,
  },
  {
    title: "Instruct (Direction)",
    description: `You need to practice that more so it becomes second nature to you. `,
  },
  {
    title: "Curiosity",
    description: `Do you have any idea how we can avoid that moving forward?`,
  },
];

export const oneToOneTalkTurns = [
  {
    input: "Hi Riley, I appreciate you taking the time to meet with me. How are you today?",
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `I'm doing well. How are you?`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Doing okay, thank you for asking. We have quite a few agenda items that we 
      need to cover today but I wanted to start with a few questions to get an idea of how 
      things are going for you overall since we are coming up on 2 months of you being here. 
      How does that sound to you?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `That works for me. I do have a few questions about upcoming 
      projects I wanted to discuss.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `For sure. Let's get started then. Overall how are you enjoying your time here? 
      What is going well?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Thank you for asking! There is a lot going well I would say. But 
      mostly I have a super supportive team and it makes a big difference when we are 
      working on these tough projects`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `That's great to hear. It sounds like your team has really helped you. `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Yes, I think this is a challenging job and we are solving hard 
      problems but there is a lot of knowledge on this team and we work well together. I never 
      feel like I'm working on things alone.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Hmm. Yes. Is there anything else?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Yeah I'm also really enjoying the flexibility that we have. I can be 
      really creative here and I haven't gotten to do that in previous jobs.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: "I love that. You said creativity is important to you. Is that right? ",
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `
      Yes, I thrive in places where I can do creative problem solving and I 
      think you have done an amazing job at creating an environment where we can do that. `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Oh thank you for that. Is there anything that you feel isn't going well?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `In what sense? `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Are there any areas where you would like to see improvements? Anything I 
      can do that might help make your transition easier? `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `I appreciate you asking that. I'm not sure.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `And I should add that you do not have to answer if you do not feel 
      comfortable. We have other methods to get this feedback anonymously.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `The only thing that immediately comes to mind is that I think we can 
      improve our communication a bit.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: ` 
      Can you say more about that? In what ways?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: ` Yeah, so it's happened a few times that we don't get all of the 
      information that we need to complete a project. So we get halfway through and then it's 
      like “oh no we didn't do this right because a vital piece of information was missing.”`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Can you give an example of a time that happened?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Yes, so for this project that just wrapped up. This was my first 
      deliverable so I was excited. I was compiling our final report for our customer, and as 
      you were reviewing you gave me a lot of edits and said there was a lot of information 
      missing. `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Okay...`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `But when I looked back through our communication, you never gave 
me that information to start with. It was a bit discouraging and I think it would have been 
helpful to have that from the start.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `If I'm hearing you correctly, there was some information that didn't get passed 
      to you so you couldn't do the report accurately.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Yes that is correct. `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Do you have any idea how we can avoid that moving forward?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `I think maybe we do a detailed project kickoff. That was missing this 
      time and I'm concerned this will continue to happen if we don't discuss expectations as a
      team`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `That's a great idea and you could also take some initiative to get those kinds 
      of things scheduled. That's the kind of stuff I really want to see from you.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Okay`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `I want to make sure that we have time to get your questions answered. If you 
      don't mind, I would like to switch gears and give you some feedback. `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `That's fine`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `How do you think you've been doing overall?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Okay I guess. I get team processes but I am struggling with 
      translating customer requests to the team.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Why do you think that is?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `This is a new domain for me so I feel like I'm learning a new 
      language which I then have to turn into workstreams. s`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Yes I would agree. As I said, I would like to see you take the lead more in 
      meetings. You haven't been doing that as much as I would like and it's a concern.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `
      Yeah I think that's my biggest area of growth.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Agreed. You need to practice that more so it becomes second nature to you. 
      I'm worried you won't grow in that area otherwise.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Right. How do you suggest I do that?`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `You should be shadowing more people right now before doing this on your 
      own. Have you had a chance to do that?`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: ` No I was kind of just thrown in.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `This is exactly what you were discussing earlier about communication. I will 
      make sure that you can shadow some upcoming team lead meetings.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `I think I -`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Okay? And don't wait until our meetings to try to get your questions answered.
      We can address them in between meetings as well.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Okay. Thank you for that. `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Otherwise, you are already showing that you are a great asset to the team.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Okay so you're saying my main area of improvement is with taking 
      more of a leadership role?`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Yes.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Okay thank you that is good feedback for me. Will we have time to 
      cover some of the questions that I had? `,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Let's schedule additional time to go over your questions. Is it okay if we 
      schedule at the end of the day? `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Yes that should work.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Okay great. Thanks for your flexibility. Is there anything else here that you 
      want to cover? `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `No I think we covered everything there and thank you for the 
      feedback.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Of course. Thank you for your feedback as well. `,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `No problem. I will talk to you in a couple of hours.`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: `Okay. Take care.`,
    speaker: { id: "Eli Wood", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `Bye`,
    speaker: { id: "Riley Green-Neam", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
];

export const InsightsOneToOneApi = [
  {
    title: "# request",
    apiEndPoint: `POST`,
    code: JSON.parse(`{
      "talk_turns": [
        {
          "input": "Hi Riley, I appreciate you taking the time to meet with me. How are you today?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "I'm doing well. How are you?",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        }, 
        {
          "input": "Doing okay, thank you for asking. We have quite a few agenda items that we need to cover today but I wanted to start with a few questions to get an idea of how things are going for you overall since we are coming up on 2 months of you being here.How does that sound to you ? ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "That works for me. I do have a few questions about upcoming projects I wanted to discuss.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "For sure. Let's get started then. Overall how are you enjoying your time here? What is going well ? ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Thank you for asking! There is a lot going well I would say. But mostly I have a super supportive team and it makes a big difference when we are working on these tough projects ",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "That's great to hear. It sounds like your team has really helped you. ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yes, I think this is a challenging job and we are solving hard problems but there is a lot of knowledge on this team and we work well together.I never feel like I 'm working on things alone.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Hmm. Yes. Is there anything else?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yeah I'm also really enjoying the flexibility that we have. I can be really creative here and I haven 't gotten to do that in previous jobs.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "I love that. You said creativity is important to you. Is that right? ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yes,I thrive in places where I can do creative problem solving and I think you have done an amazing job at creating an environment where we can do that.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Oh thank you for that. Is there anything that you feel isn't going well?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "In what sense? ",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Are there any areas where you would like to see improvements? Anything I can do that might help make your transition easier ? ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "I appreciate you asking that. I'm not sure.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "And I should add that you do not have to answer if you do not feel comfortable.We have other methods to get this feedback anonymously.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "The only thing that immediately comes to mind is that I think we can improve our communication a bit.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Can you say more about that ? In what ways ? ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": " Yeah, so it's happened a few times that we don't get all of the information that we need to complete a project.So we get halfway through and then it's like “oh no we didn't do this right because a vital piece of information was missing.”",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Can you give an example of a time that happened?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yes, so for this project that just wrapped up. This was my first deliverable so I was excited.I was compiling our final report for our customer,and as you were reviewing you gave me a lot of edits and said there was a lot of information missing",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Okay...",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "But when I looked back through our communication, you never gave me that information to start with.It was a bit discouraging and I think it would have been helpful to have that from the start.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "If I'm hearing you correctly, there was some information that didn't get passed to you so you couldn 't do the report accurately.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yes that is correct. ",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Do you have any idea how we can avoid that moving forward?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "I think maybe we do a detailed project kickoff. That was missing this time and I 'm concerned this will continue to happen if we don't discuss expectations as a team",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "That's a great idea and you could also take some initiative to get those kinds  of things scheduled.That 's the kind of stuff I really want to see from you.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Okay",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "I want to make sure that we have time to get your questions answered. If you don 't mind, I would like to switch gears and give you some feedback. ",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "That's fine",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "How do you think you've been doing overall?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": "Okay I guess. I get team processes but I am struggling with translating customer requests to the team.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Why do you think that is?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "This is a new domain for me so I feel like I'm learning a new language which I then have to turn into workstreams.s ",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Yes I would agree. As I said, I would like to see you take the lead more in meetings.You haven 't been doing that as much as I would like and it's a concern.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": "Yeah I think that 's my biggest area of growth.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Agreed. You need to practice that more so it becomes second nature to you.I 'm worried you won't grow in that area otherwise.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Right. How do you suggest I do that?",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "You should be shadowing more people right now before doing this on your own.Have you had a chance to do that ?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": " No I was kind of just thrown in.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "This is exactly what you were discussing earlier about communication. I will make sure that you can shadow some upcoming team lead meetings.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "I think I -",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Okay? And don't wait until our meetings to try to get your questions answered.We can address them in between meetings as well.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": "Okay. Thank you for that. ",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Otherwise, you are already showing that you are a great asset to the team.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Okay so you're saying my main area of improvement is with taking more of a leadership role ?",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Yes.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": "Okay thank you that is good feedback for me. Will we have time to cover some of the questions that I had ?",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Let's schedule additional time to go over your questions. Is it okay if we schedule at the end of the day ?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Yes that should work.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Okay great. Thanks for your flexibility. Is there anything else here that you want to cover ?",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
    
        {
          "input": "No I think we covered everything there and thank you for the feedback.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Of course. Thank you for your feedback as well.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "No problem. I will talk to you in a couple of hours.",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": "Okay. Take care.",
          "speaker": {
            "id": "Eli Wood",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "Bye",
          "speaker": {
            "id": "Riley Green-Neam",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        }
      ]
    }`),
  },
  {
    title: "# response",
    code: JSON.parse(`{
      "conversation": {
          "sentence_count": 104,
          "talk_turn_count": 58,
          "frequencies": {
              "strength_based": 0.135,
              "toxicity": 0
          },
          "metrics": {
              "empathy": 5.1,
              "collaboration": 2,
              "curiosity": 3.2,
              "synchrony": -1
          }
      },
      "speakers": [
          {
              "id": "Eli Wood",
              "role": "Therapist",
              "sentence_count": 5,
              "talk_turn_count": 2,
              "frequencies": {
                  "strength_based": 0.4,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 10,
                  "collaboration": -1,
                  "curiosity": 10
              }
          },
          {
              "id": "Riley Green-Neam",
              "role": "Patient",
              "sentence_count": 45,
              "talk_turn_count": 28,
              "frequencies": {
                  "strength_based": 0.133,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 7,
                  "collaboration": 0,
                  "curiosity": 5
              }
          },
          {
              "id": "Eli Wood",
              "role": "Therapist",
              "sentence_count": 53,
              "talk_turn_count": 27,
              "frequencies": {
                  "strength_based": 0.113,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 4,
                  "collaboration": 2.5,
                  "curiosity": 2.5
              }
          },
          {
              "id": "Riley Green-Neam",
              "role": "Patient",
              "sentence_count": 1,
              "talk_turn_count": 1,
              "frequencies": {
                  "strength_based": 0,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": -1,
                  "collaboration": -1,
                  "curiosity": -1
              }
          }
      ]
  }`),
  },
];

// Hiring

export const hiringHighlights = [
  {
    title: "Appreciation",
    description: `It's wonderful that you are so engaged in the community! `,
  },
  {
    title: "Reflective Listening",
    description: `I'm hearing that you were involved in many tasks, saw projects from start to finish, and 
    did so quite independently. `,
  },
  {
    title: "Curiosity",
    description: `What aspects of team culture do you like?`,
  },
];

export const hiringLowlights = [
  {
    title: "Confront",
    description: `No, you must have misunderstood.`,
  },
  {
    title: "Toxicity (Insult)",
    description: `Yeah, from what you've shared they seem a little incompetent`,
  },
  {
    title: "Instruct (Direction)",
    description: `You need to be very communicative with us though. `,
  },
];

export const hiringTalkTurns = [
  {
    input: `Hi Blake, I'm Aaliyah, I use she/her pronouns and I am our company's hiring 
      manager. It's nice to meet you.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:00", end: "00:00:03" },
  },
  {
    input: `It's nice to meet you too, Aaliyah. I use they/them pronouns and am happy to be 
      here. Thanks so much for meeting with me today. `,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:05", end: "00:00:10" },
  },
  {
    input: ` Absolutely. My team was really impressed with your resume and CV, and so I'd
      like to start by going through a few questions to assess our fit. How does that sound to 
      you?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:11", end: "00:00:17" },
  },
  {
    input: `That sounds great to me.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Fantastic. To start off, what interests you in this job? `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `So I do similar research-related work at my current job that's in the marketing 
      setting, but I'm much more interested in research that focuses on social and 
      environmental justice.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Mm. Why social and environmental justice? Do you engage in related 
      activism?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I do! In my free time I regularly volunteer with a few local grassroots 
      organizations, which I'm super passionate about.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `That sounds so fulfilling. It's wonderful that you are so engaged in the 
      community! `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Thank you. It is why I'm seeking a change in my career trajectory now. `,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `That makes sense. I know that you have research experience, but can you tell 
      me more about your experience and how your last position has prepared you for this 
      role? `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `
      Certainly. In my work, I helped develop qualitative and quantitative surveys, 
      gathered and recorded data on both small and large scales, done data analysis, and 
      prepared endless reports and presentations regarding various products with little 
      support.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `
      I'm hearing that you were involved in many tasks, saw projects from start to 
      finish, and did so quite independently.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Yea. Honestly, my current team culture is horrible.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Yeah, from what you've shared they seem a little incompetent. It must have 
      been tough working in an environment with poor culture. `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `
      It was so tough. So that's the other reason why I'm interested in this new 
      position.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Right. On that note, what aspects of team culture do you like?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I enjoy feeling like a part of a cohesive team that communicates well and 
      supports one another to get their work done.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Agreed. That seems like a healthy work environment to me. What's nice about 
      this role is that it's up to you how much or how little you want to engage as long as 
      you're getting your work done. You need to be very communicative with us though.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I like that. I really just like your company as a whole.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Tell me more about that, I'd love to hear.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: ` Well your mission, vision and values really align with mine, and the fact that you 
      all do meaningful work and make positive impacts in your community is really a breath of
      fresh air. `,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Thank you Blake. I really appreciate hearing that and know my team would too.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I'm just calling it how I see it (laughing).`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Fair enough. Alright I'm going to ask you a few more questions and then you 
      can ask me some questions you might have.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Okay great.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `In your opinion, what are your greatest strengths and weaknesses?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `That's tough (laughing).`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Take your time.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Okay I'll start with a weakness. I think my greatest weakness is that I can be 
      overly people-pleasing. I think that's why I do so much work on my own in my current 
      job...because I have a hard time saying no. `,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `A lot of people struggle with that, it's totally understandable. Thank you for 
      sharing.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Of course. As for my greatest strength...I believe that I am highly organized, 
      detailed-oriented and meticulous about my work.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `From the sample works you shared with us, that doesn't surprise me!`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `That makes me happy, thanks for acknowledging that. This work can be difficult.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `My pleasure. Okay last question. Why do you think we should hire you?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I think your team should hire me for the many reasons I've mentioned. Alignment
      in mission, vision and values, my passion for social and environmental justice, and a 
      work culture that seems to flow well with my personal style. More importantly, I'm excited
      and motivated to be able to bring my experience and knowledge to an area that I love, 
      and to learn from people I highly respect and admire in order to grow.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Fantastic. Thanks again for sharing. With that said I know that other folks on 
      my team are eager to meet you. After this, you will meet the rest of the team altogether 
      and then we will be in touch with you by the end of this week. `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Wow. I'm nervous, but looking forward to meeting everyone.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Don't be nervous. You will be just fine. Now what questions do you have for 
      me?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Thanks, I appreciate the support. My question is, if I were to get hired what 
      advice do you have for me on how to be successful here?`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `My advice would be to bring your whole, authentic self every day. You may not 
      always be able to bring your best self to this type of work everyday. So you should bring 
      your whole self, and just trying your best in a given day. And you should practice saying 
      no. `,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Yeah I need to stop saying yes to everything. It's forever a work in progress.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I think many would agree (laughing).`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: ` My last question is in regards to salary. I recall the listing had the starting salary 
      range at $100,000 a year, is that negotiable?`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Great question. Yes, salary is negotiable. So the starting range is actually 
      $80,000 a year.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Hmm. I'm a bit concerned. That's not what you said in your recruiting 
      presentation.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `No, you must have misunderstood. The starting range is $80,000 per year, but 
      we take into account your history and experience, so for someone with your experience, 
      it could be more along with lines of $100,000 a year base pay.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Ah okay. Thanks for clarifying.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `No problem. Do you have any more questions for me?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `I don't think so.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Wonderful. Well if you have any more questions, please feel free to email me.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Thank you, I definitely will.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `
      It was so nice meeting you, Blake.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `You too, Aaliyah. Thank you for your time today.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `It was my pleasure. Now are you ready to meet the rest of the team?`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `No and yes.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `(laughing)`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Just kidding. Yes of course I am, I am actually really excited.`,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Great, go ahead and follow me to the office next door.`,
    speaker: { id: "Aaliyah", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
  {
    input: `Okay here we go. Wish me luck. `,
    speaker: { id: "Blake", role: "Patient" },
    timestamp: { start: "00:00:19", end: "00:00:29" },
  },
];

export const InsightshiringApi = [
  {
    title: "# request",
    apiEndPoint: `POST`,
    code: JSON.parse(`{
      "talk_turns": [{
          "input": "Hi Blake, I'm Aaliyah, I use she/her pronouns and I am our company's hiring manager. It's nice to meet you.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:00",
            "end": "00:00:03"
          }
        },
        {
          "input": "It's nice to meet you too, Aaliyah. I use they/them pronouns and am happy to be here. Thanks so much for meeting with me today. ",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:05",
            "end": "00:00:10"
          }
        },
        {
          "input": " Absolutely. My team was really impressed with your resume and CV, and so I'like to start by going through a few questions to assess our fit. How does that sound to you?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:11",
            "end": "00:00:17"
          }
        },
        {
          "input": "That sounds great to me.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Fantastic. To start off, what interests you in this job? ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "So I do similar research-related work at my current job that's in the marketing setting, but I'm much more interested in research that focuses on social and environmental justice.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Mm. Why social and environmental justice? Do you engage in related activism?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I do! In my free time I regularly volunteer with a few local grassroots organizations, which I'm super passionate about.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "That sounds so fulfilling. It's wonderful that you are so engaged in the community! ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Thank you. It is why I'm seeking a change in my career trajectory now. ",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "That makes sense. I know that you have research experience, but can you tell me more about your experience and how your last position has prepared you for this role? ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Certainly.In my work,I helped develop qualitative and quantitative surveys,gathered and recorded data on both small and large scales,done data analysis,and prepared endless reports and presentations regarding various products with little support.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I'm hearing that you were involved in many tasks, saw projects from start to finish, and did so quite independently.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Yea. Honestly, my current team culture is horrible.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Yeah, from what you've shared they seem a little incompetent. It must have been tough working in an environment with poor culture. ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "It was so tough.So that's the other reason why I'm interested in this new position.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Right. On that note, what aspects of team culture do you like?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I enjoy feeling like a part of a cohesive team that communicates well and supports one another to get their work done.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Agreed. That seems like a healthy work environment to me. What's nice about this role is that it's up to you how much or how little you want to engage as long as you're getting your work done. You need to be very communicative with us though.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I like that. I really just like your company as a whole.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Tell me more about that, I'd love to hear.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": " Well your mission, vision and values really align with mine, and the fact that you all do meaningful work and make positive impacts in your community is really a breath o fresh air. ",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Thank you Blake. I really appreciate hearing that and know my team would too.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I'm just calling it how I see it (laughing).",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Fair enough. Alright I'm going to ask you a few more questions and then you can ask me some questions you might have.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Okay great.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "In your opinion, what are your greatest strengths and weaknesses?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "That's tough (laughing).",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Take your time.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Okay I'll start with a weakness. I think my greatest weakness is that I can be overly people-pleasing. I think that's why I do so much work on my own in my current job...because I have a hard time saying no. ",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "A lot of people struggle with that, it's totally understandable. Thank you for sharing.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Of course. As for my greatest strength...I believe that I am highly organized, detailed-oriented and meticulous about my work.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "From the sample works you shared with us, that doesn't surprise me!",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "That makes me happy, thanks for acknowledging that. This work can be difficult.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "My pleasure. Okay last question. Why do you think we should hire you?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I think your team should hire me for the many reasons I've mentioned. Alignmen in mission, vision and values, my passion for social and environmental justice, and a work culture that seems to flow well with my personal style. More importantly, I'm excite and motivated to be able to bring my experience and knowledge to an area that I love, and to learn from people I highly respect and admire in order to grow.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Fantastic. Thanks again for sharing. With that said I know that other folks on my team are eager to meet you. After this, you will meet the rest of the team altogether and then we will be in touch with you by the end of this week. ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Wow. I'm nervous, but looking forward to meeting everyone.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Don't be nervous. You will be just fine. Now what questions do you have for me?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Thanks, I appreciate the support. My question is, if I were to get hired what advice do you have for me on how to be successful here?",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "My advice would be to bring your whole, authentic self every day. You may not always be able to bring your best self to this type of work everyday. So you should bring your whole self, and just trying your best in a given day. And you should practice saying no. ",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Yeah I need to stop saying yes to everything. It's forever a work in progress.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I think many would agree (laughing).",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": " My last question is in regards to salary. I recall the listing had the starting salary range at $100,000 a year, is that negotiable?",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Great question. Yes, salary is negotiable. So the starting range is actually $80,000 a year.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Hmm. I'm a bit concerned. That's not what you said in your recruiting presentation.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "No, you must have misunderstood. The starting range is $80,000 per year, but we take into account your history and experience, so for someone with your experience, it could be more along with lines of $100,000 a year base pay.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Ah okay. Thanks for clarifying.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "No problem. Do you have any more questions for me?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "I don't think so.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Wonderful. Well if you have any more questions, please feel free to email me.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Thank you, I definitely will.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "It was so nice meeting you,Blake.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "You too, Aaliyah. Thank you for your time today.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "It was my pleasure. Now are you ready to meet the rest of the team?",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "No and yes.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "(laughing)",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Just kidding. Yes of course I am, I am actually really excited.",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Great, go ahead and follow me to the office next door.",
          "speaker": {
            "id": "Aaliyah",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        },
        {
          "input": "Okay here we go. Wish me luck. ",
          "speaker": {
            "id": "Blake",
            "role": "Patient"
          },
          "timestamp": {
            "start": "00:00:19",
            "end": "00:00:29"
          }
        }
      ]
    }`),
  },
  {
    title: "# response",
    code: JSON.parse(`{
      "conversation": {
          "sentence_count": 116,
          "talk_turn_count": 60,
          "frequencies": {
              "strength_based": 0.224,
              "toxicity": 0
          },
          "metrics": {
              "empathy": 7.5,
              "collaboration": 2,
              "curiosity": 6.4,
              "synchrony": -1
          }
      },
      "speakers": [
          {
              "id": "Aaliyah",
              "role": "Therapist",
              "sentence_count": 5,
              "talk_turn_count": 2,
              "frequencies": {
                  "strength_based": 0.4,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 10,
                  "collaboration": -1,
                  "curiosity": 10
              }
          },
          {
              "id": "Blake",
              "role": "Patient",
              "sentence_count": 54,
              "talk_turn_count": 30,
              "frequencies": {
                  "strength_based": 0.222,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 9.3,
                  "collaboration": -1,
                  "curiosity": 5
              }
          },
          {
              "id": "Aaliyah",
              "role": "Patient",
              "sentence_count": 57,
              "talk_turn_count": 28,
              "frequencies": {
                  "strength_based": 0.211,
                  "toxicity": 0
              },
              "metrics": {
                  "empathy": 6.5,
                  "collaboration": 2,
                  "curiosity": 6.4
              }
          }
      ]
  }`),
  },
];

// Sales

export const salesHighlights = [
  {
    title: "Autonomy",
    description: `You’re in control of next steps here and where to take this, we are happy to move to costs.`,
  },
  {
    title: "Reflective Listening",
    description: ` Yes, this sounds like the most important problem for your company right now. You are really concerned about it.`,
  },
  {
    title: "Appreciation",
    description: `We really appreciate you bringing your number one concerns up.`,
  },
]

export const salesLowlights = [
  {
    title: "Confront",
    description: ` I don’t think it works like that.`,
  },
  {
    title: "Confront",
    description: `….so actually, I think you are actually getting a bit off topic.`,
  },
  {
    title: "Instruct (Direction)",
    description: `I’m going to stop you.`,
  },
  {
    title: "Curiosity",
    description: `Can we just cut to the chase here?`
  }
]

export const salesTalkTurns = [
  {
    input: `Hello everyone! Nice to see you all again. Hello Monique and Regina! Mr. Jolley, I haven't met you yet. How are you?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:00:00`, end: `00:00:10`}
  },
  {
    input: `Hello I'm Mr. Ellis Jolley. Please call me Ellis.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:00:13`, end: `00:00:35`}
  },
  {
    input: `Great to meet you! I've heard a lot about you. I'll introduce myself, I'm Rosita and I am a product lead and I'd like to introduce our services and discuss what we can provide to you. I know your platform is growing in the market and we want to keep helping! I'll also introduce my colleague Saul.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:00:38`, end: `00:01:00`}
  },
  {
    input: `Hello Everyone! How is everyone?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:01:03`, end: `00:01:26`}
  },
  {
    input: `Great! Good to meet you. How are you?`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:01:29`, end: `00:01:51`}
  },
  {
    input: `Can we just cut to the chase here? I've been on meetings all day and don't want to be rude but we need to get an agenda started.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:01:54`, end: `00:02:16`}
  },
  {
    input: `No problem. We left off last time. Sorry. Sorry. We left off last time and. Or rather, did you have particular questions I can answer or I can go into detail? What do you want to hear about specifically today? We can discuss security. I remember you said last time that sounded like a primary concern for you. I also want to hear about any changes that are the focus of what you are interested in. `, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:02:19`, end: `00:02:41`}
  },
  {
    input: `Yes, I agree making sure data is stored securely and we can reach it. I don't think its been explained to us.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:02:44`, end: `00:03:07`}
  },
  {
    input: `Okay great yes we can discuss that. We can discuss multifactor authentication and.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:03:10`, end: `00:03:32`}
  },
  {
    input: `I think we are missing a key point. We had some serious cyberattacks and that's cost us a lot. Tell us about -`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:03:35`, end: `00:03:57`}
  },
  {
    input: `Yes, you encountered a lot of problems that were really bad.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:04:00`, end: `00:04:22`}
  },
  {
    input: `Mishaps yes.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:04:25`, end: `00:04:47`}
  },
  {
    input: `If you were to join us we have a - I'm sorry my connection is not strong. So the main idea behind our security and we know you work with major enterprise companies is that we would be protecting you from cyberattacks. That's our main focus.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:04:50`, end: `00:05:13`}
  },
  {
    input: `Our data is very critical we deal with extremely confidential files. We need a 100% guarantee that our data is secure. We cannot take risks.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:05:16`, end: `00:05:38`}
  },
  {
    input: `Yes, this sounds like the most important problem for your company right now. You are really concerned about it. We’ve worked with fortune 500 companies and we have never encountered a breach. We have multifactor authentication and.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:05:41`, end: `00:06:03`}
  },
  {
    input: `Other companies have said they could provide us this security and it didn't happen.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:06:06`, end: `00:06:28`}
  },
  {
    input: `Unfortunately for us. No one is stepping up to the plate. No one keeps their word. It's cost us. AT this point people just make promises to us and then honestly, I am scared to take another risk. I don't really want to put my company on the line and face a very very costly risk.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:06:31`, end: `00:06:54`}
  },
  {
    input: `But we have the best product so, this really won't happen, so many users have attested to our security and we have permissions with different accesses and encryption; we even offer finger print access.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:06:57`, end: `00:07:19`}
  },
  {
    input: `You need to be able to guarantee me and I am not sure I am convinced.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:07:22`, end: `00:07:44`}
  },
  {
    input: `Sounds like for us to be successful we need to learn exactly about where those companies failed and to show you how we would be different, and to really back this up.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:07:47`, end: `00:08:09`}
  },
  {
    input: `Yes, exactly!`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:08:12`, end: `00:08:34`}
  },
  {
    input: `Yes yes. That's it.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:08:37`, end: `00:09:00`}
  },
  {
    input: `Thanks for that Saul, yes.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:09:03`, end: `00:09:25`}
  },
  {
    input: `Tell us more about the problems you've been facing. What kinds of things have been costly?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:09:28`, end: `00:09:50`}
  },
  {
    input: `Here are the problems. With some of the files we have, we need multiple levels of guarantees - for government for defense that even if someone were to break in that we put like you said a finger print and multifactor. Those are important to us.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:09:53`, end: `00:10:15`}
  },
  {
    input: `We have so many different features we can offer you! We have single cloud sign on. And actually we have a company that is really excited about that who gave us a great wonderful reference.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:10:18`, end: `00:10:41`}
  },
  {
    input: `What are some of the other problems?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start:`00:10:44`, end: `00:11:06`}
  },
  {
    input: `Well other companies weren't current and then we faced getting hacked.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:11:09`, end: `00:11:31`}
  },
  {
    input: `Bug fixes and maintence are on top. This is really easy to fix. We have new updates. So let's talk a little bit more about fingerprints`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:11:34`, end: `00:11:56`}
  },
  {
    input: `I'm going to stop you. Before we go on, can we`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:11:59`, end: `00:12:21`}
  },
  {
    input: `Yes I was going to say, we need to talk about support. Is that what you were going to say?`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:12:24`, end: `00:12:47`}
  },
  {
    input: `Yes support`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:12:50`, end: `00:13:12`}
  },
  {
    input: `We have an on-demand 24-7 support and team.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:13:15`, end: `00:13:37`}
  },
  {
    input: `Sounds like you may have had problems in the past with support?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:13:40`, end: `00:14:02`}
  },
  {
    input: `Yes, Do you work on weekends and holidays? Is your system on?`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:14:05`, end: `00:14:28`}
  },
  {
    input: `This is where the breakdown happens. Companies never have full support.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:14:31`, end: `00:14:53`}
  },
  {
    input: `We never sleep. Our system never sleeps.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:14:56`, end: `00:15:18`}
  },
  {
    input: `Our support teams are very happy and well compensated and they love working on weekends.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:15:21`, end: `00:15:43`}
  },
  {
    input: `It really seems like the most important thing to you is to have absolutely watertight, certified, 24-7 security.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:15:46`, end: `00:16:08`}
  },
  {
    input: `All of this is very convincing to me. It's just a huge risk and let's talk about costs.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:16:11`, end: `00:16:34`}
  },
  {
    input: `We're happy to move to that part of the discussion. We really appreciate you bringing your number one concerns up.`, 
    speaker: {id: `Rosita`, role: `Seller`},

    timestamp: {start: `00:16:37`, end: `00:16:59`}
  },
  {
    input: `I mean let's see about the cost before we get too excited here. It's a big risk but it's going to be a big cost, right?`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:17:02`, end: `00:17:24`}
  },
  {
    input: `Right is it going treally be worth it to us, we need to know exactly what this will cost.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:17:27`, end: `00:17:49`}
  },
  {
    input: `I need to talk actually a bit more about budget with Gary.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:17:52`, end: `00:18:15`}
  },
  {
    input: `You're in control of next steps here and where to take this, we are happy to move to costs.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:18:18`, end: `00:18:40`}
  },
  {
    input: `Yes, but I have two questions first, because I think we would like to set up a face to face meeting to really understand what needs to be done and go to your headquarters.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:18:43`, end: `00:19:05`}
  },
  {
    input: `Where are you located?`,
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:19:08`, end: `00:19:30`}
  },
  {
    input: `All over the country. We have 40 branches.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:19:33`, end: `00:19:55`}
  },
  {
    input: `More branches equals more risk in my opinion. Data spread around everywhere. You need one location.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:19:58`, end: `00:20:21`}
  },
  {
    input: `I don't think it works like that.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:20:24`, end: `00:20:46`}
  },
  {
    input: `I don't see it as a problem because our team can meet you where you are at in person.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:20:49`, end: `00:21:11`}
  },
  {
    input: `What's your concern Monique?`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:21:14`, end: `00:21:36`}
  },
  {
    input: `In our company in the past you know when we expanded everything broke down, like they would tell us to fix a bug in branch a and then not in branch b and then branch C would transfer us and it was a big pain. Complex and delay.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:21:39`, end: `00:22:02`}
  },
  {
    input: `Actually Monique is right and I hope we don't need to play that game with you. I know we won't be closing any deals if that's the case.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:22:05`, end: `00:22:27`}
  },
  {
    input: `[Laughs] We better not!`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:22:30`, end: `00:22:52`}
  },
  {
    input: `That's actually a really good point, I can see how that would have been a problem in some of the older systems. It's not something to worry about with us. It's an advantage. Our customers love us and we expand and so actually, I think you are actually getting a bit off topic. So let's get back to integration.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:22:55`, end: `00:23:17`}
  },
  {
    input: `Well we need time to discuss internally about budgets before we can move forward.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:23:20`, end: `00:23:42`}
  },
  {
    input: `Yes, it's been a pleasure speaking today but let's pause here so we can review your materials and get back to you about next steps.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:23:45`, end: `00:24:08`}
  },
  {
    input: `Completely understand, you set the pace and we'll follow. We'd love to see you again, and as Rosita said we'll follow-up in person hopefully soon.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:24:11`, end: `00:24:33`}
  },
  {
    input: `Thank you so much! And I apologize for my connection. And yes I want to see you all again. In person.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:24:36`, end: `00:24:58`}
  },
  {
    input: `No problem. Yes thank you so much thank you for the details.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:25:01`, end: `00:25:23`}
  },
  {
    input: `We have a lot to work with thank you.`, 
    speaker: {id: `Ellis`, role: `Buyer`},
    timestamp: {start: `00:25:26`, end: `00:25:49`}
  },
  {
    input: `Thank you and thanks again and see you all soon!`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:25:52`, end: `00:26:14`}
  },
  {
    input: `Thanks everytime, thank you. Bye.`, 
    speaker: {id: `Rosita`, role: `Seller`},
    timestamp: {start: `00:26:17`, end: `00:26:39`}
  }
]

export const InsightsSalesApi = [
  {
    title: "# request",
      apiEndPoint: `POST`,
      code: JSON.parse(`{

    }`)
  }
]
