import React, { useState } from "react";
import suggestionsImage from "../assets/suggestions.png";
import { getClassificationsAPI } from "../api/api-common";
import ReactJson from 'react-json-view';
import LoadingAnimation from "../components/base/LoadingAnimation";
import Button from "../components/base/Button";
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from "@mui/material/styles";
import ColumnChart from "../components/Charts/ColumnChart";
import Col from "react-bootstrap/esm/Col";

interface DetectionTip {
  text: string;
  tip_type: string;
}

interface Behavior {
  name: string;
  confidence: number;
  tip: DetectionTip;
}

interface Detection {
  index: number;
  text: string;
  behaviors: Behavior[];
}

interface ClassificationDetail {
  probability: number;
  confidence: number;
  prediction: boolean;
  severity: string | null;
  status: string;
  reason: string;
}

interface ModelClassification {
  model_name: string;
  model_version: string;
  classifications: { [key: string]: ClassificationDetail };
}

interface TextClassification {
  index: number;
  text: string;
  model_classifications: ModelClassification[];
}

interface TextData {
  id: string;
  created_at: number;
  detections: Detection[];
  text_classifications: TextClassification[];
  api_version: string;
}

const MpathicSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 5,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#ff00c1' : '#ff00c1',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#999' : '#999',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Classifications = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [text, setText] = useState<any>([]);
  const [classificationsAPIResponse, setClassificationsAPIResponse] = useState<any>([]);
  const [isGraphView, setGraphView] = useState<boolean>(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };
  const handleButtonClick = () => {
    setIsLoading(true);
    getClassificationsAPI(text)
      .then((res) => {
        console.log(res);
        setClassificationsAPIResponse(res.data);
        setIsLoading(false);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err);
        console.log(err);
      });
    console.log(text);
  };
  const handleViewChange = () => {
    isGraphView ? setGraphView(false) : setGraphView(true);
  };

  const prepareHighchartsSeries = (data: TextData) => {
    let probabilitiesSeries: { name: string; data: number[] } = { name: 'Probabilities', data: [] };
    let confidencesSeries: { name: string; data: number[] } = { name: 'Confidences', data: [] };
    let categories: string[] = [];
    
    data?.text_classifications?.forEach((classification) => {
        classification.model_classifications.forEach((modelClassification) => {
            Object.entries(modelClassification.classifications).forEach(([classificationName, detail]) => {
                
                // Use model name and classification name as category
                const categoryName = `${modelClassification.model_name} (${classificationName})`;
                categories.push(categoryName);

                probabilitiesSeries.data.push(detail.probability);
                confidencesSeries.data.push(detail.confidence);
            });
        });
    });

    return { series: [probabilitiesSeries, confidencesSeries], categories };
};

console.log(prepareHighchartsSeries(classificationsAPIResponse));

  return (
    <div className="classifications-container">
      {isLoading && <LoadingAnimation />}
      <div className="flex flex-row flex-wrap">
        <div className="classifications-left w-20-l pa4">
          <div className="flex breadcrumbs f6 justify-between items-center w-full">
            <div>
              <a href="/">Home</a> &nbsp; &gt; &nbsp;
              <a href="/classifications">API</a>
            </div>

          </div>
          <div className="flex flex-column flex-row-m flex-column-l justify-between items-start items-center-m pt4 pv4-l">
            <div>
              <img src={suggestionsImage} alt="API" />
            </div>
            <div>
              <h3>
                mpathic API
              </h3>
              <p></p>
            </div>
          </div>
        </div>
        <div className="classifications-center w-50-m w-40-l w-50-xl pa4 pt5-l">
          <div className="vh-75 overflow-auto bg-white br4 box-shadow">
            <div className="correction-window">
              <div className="correction-window-body">
                <div className="correction-wrapper">
                  <div className="input-wrapper relative">
                    <div className="flex justify-end p-1">
                      <FormControlLabel control={<MpathicSwitch checked={isGraphView} onChange={handleViewChange} />} label="" />
                    </div>
                    <textarea className="input"
                      rows={3}
                      value={text}
                      onChange={handleInputChange}
                      placeholder="Enter some text..."
                    />
                    <Button className="insight-home-btn mb3" onClick={handleButtonClick}>Submit</Button>
                  </div>
                </div>
              </div>
              <div className="form-container">
                <div className="block justifty-right items-end">
                </div>
                <div>
                  {isLoaded &&
                    <>
                      {isGraphView ? (
                        <div className="flex items-center justify-center h-screen">
                          <ColumnChart data={prepareHighchartsSeries(classificationsAPIResponse)} />
                        </div>
                      ) : (
                        <div className="flex flex-column items-start">
                        <ReactJson src={{ text: classificationsAPIResponse }} theme="summerfruit:inverted" iconStyle="circle" name={false} sortKeys={true} collapsed={true} />
                        </div>
                      )
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classifications;