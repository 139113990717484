import React from "react";

const LoadingAnimation = () => {
  return (
    <div id="loading-wrapper" className="">
      <svg
        version="1.1"
        id="loading"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
      >
        <g className="left-side">
          <path
            className="side"
            fill="#FCD703"
            d="M16.1,52.8H3.4c-1.1,0-2-0.9-2-2V4.3c0-1.1,0.9-2,2-2h12.6c1.1,0,2,0.9,2,2v46.5
        C18.1,51.9,17.2,52.8,16.1,52.8z"
          />
          <path
            className="arm"
            fill="#FCD703"
            d="M36.9,40.3L29,45.1c-1,0.6-2.2,0.2-2.8-0.7L6.3,10C5.7,9,6,7.7,7,7.2l8-4.6c1-0.6,2.2-0.2,2.8,0.7l19.8,34.3
        C38.2,38.5,37.8,39.8,36.9,40.3z"
          />
        </g>
        <g className="right-side">
          <path
            className="side"
            fill="#FF00C1"
            d="M56.6,57.7H43.8c-1.1,0-2-0.9-2-2V9.2c0-1.1,0.9-2,2-2h12.8c1.1,0,2,0.9,2,2v46.5
        C58.6,56.8,57.7,57.7,56.6,57.7z"
          />
          <path
            className="arm"
            fill="#FF00C1"
            d="M33.2,45.4c-0.9,0-3.9,0-5.2,0c-1.2,0-1.6-0.7-2.1-1.6c-0.7-1.2-1.3-2.3-1.7-3c-0.6-1.1-1.2-1.9-0.4-2.9
        L42,8.1c0.6-1,1.8-1.3,2.8-0.7l8.4,5.1c1,0.6,1.3,1.8,0.7,2.8L36.3,43.7C35.1,45.3,34.3,45.4,33.2,45.4z"
          />
        </g>
      </svg>
    </div>
  );
};

export default LoadingAnimation;
