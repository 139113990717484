export const botCustomChartColors = ["#ff00c1", "#fcd703", "#67dedf", "#000000", "#ffb1ff", "#fef5c2", "#bcf0f1", "#8f006b", "#f3ac02", "#1d8587", "#6700a9", "#fc0002", "#66bb03"]

export const botRndStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0"
}

export const botSankeyOptions = {
    sankey: {
      link:{
          colorMode: 'gradient',
          colors: botCustomChartColors
        },
      node: {
        colors: botCustomChartColors,
        label: { color: "#000" }
      }
    }
  };

export const unicodeArrow = String.fromCharCode(0x2192);

export const formatString = (str) => {
  // Replace underscores with spaces
  const formattedString = str.replace(/_/g, ' ');

  // Capitalize first letters of each word
  const words = formattedString.split(' ');
  const capitalizedWords = words.map(word =>
    word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join words back into a single string and return
  return capitalizedWords.join(' ');
}
