import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RadialBarChartComponent from "./Charts/RadialBarChart";
import { renderContributingBehaviorsTable } from "../helpers/common";

const tooltips = {
  empathy:
    "Accurate understanding. Efforts to understand another person’s point of view.",
  curiosity:
    "Encouraging others to share more information. Open versus directive.",
  collaboration:
    "Sharing power. Inviting another person’s thoughts and accepting autonomy.",
};

const Metrics = ({ apiData }) => {
  const data = apiData.metrics;

  return (
    <Container className="metrics">
      <Tabs
        defaultActiveKey="collaboration"
        id="uncontrolled-tab-example"
        className="mb-5"
        fill
      >
        <Tab eventKey="collaboration" title="Collaboration">
          <Row>
            <Col xs={12} sm={4} lg={5}>
              {data.collaboration.score !== null ? (
                <RadialBarChartComponent
                  title="Collaboration"
                  currentValue={data.collaboration.score}
                  tooltip={tooltips.collaboration}
                />
              ) : (
                "Not enough data available."
              )}
            </Col>
            <Col xs={12} sm={8} lg={7}>
              {data.collaboration.contributing_behaviors.length > 0 && (
                <p className="b mb2">Contributing Behaviors</p>
              )}
              {renderContributingBehaviorsTable(data.collaboration)}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="curiosity" title="Curiosity">
          <Row>
            <Col xs={12} sm={4} lg={5}>
              {data.curiosity.score !== null ? (
                <RadialBarChartComponent
                  title="Curiosity"
                  currentValue={data.curiosity.score}
                  tooltip={tooltips.curiosity}
                />
              ) : (
                "Not enough data available."
              )}
            </Col>
            <Col xs={12} sm={8} lg={7}>
              {data.curiosity.contributing_behaviors.length > 0 && (
                <p className="b mb2">Contributing Behaviors</p>
              )}
              {renderContributingBehaviorsTable(data.curiosity)}
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="empathy" title="Empathy">
          <Row>
            <Col xs={12} sm={4} lg={5}>
              {data.empathy.score !== null ? (
                <RadialBarChartComponent
                  title="Empathy"
                  currentValue={data.empathy.score}
                  tooltip={tooltips.empathy}
                />
              ) : (
                "Not enough data available."
              )}
            </Col>
            <Col xs={12} sm={8} lg={7}>
              {data.empathy.contributing_behaviors.length > 0 && (
                <p className="b mb2">Contributing Behaviors</p>
              )}
              {renderContributingBehaviorsTable(data.empathy)}
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Metrics;
