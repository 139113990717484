import BarChartComponent from "./Charts/BarChart";
import Table from "react-bootstrap/Table";
import { mergeTableData, mergeChartData, renderTable } from "../helpers/common";

const Counts = ({ apiData }) => {
  let barChartData = [];
  let tableData;
  let speakerNames = [];
  let composite = false;

  if (apiData.talk_turn_count !== undefined && apiData.utterance_count !== undefined) {
    barChartData = [
      {
        name: 'Talk Turn Count',
        value: apiData.talk_turn_count,
      },
      {
        name: 'Utterance Count',
        value: apiData.utterance_count,
      },
    ];
  } else {
    composite = true;
    barChartData = mergeChartData(apiData, "talk_turn_count");
    tableData = mergeTableData(apiData, "talk_turn_count");
    speakerNames = apiData.map((speaker) => speaker.id);
  }

  return (
    <>
      <BarChartComponent data={barChartData} speakers={speakerNames} composite={composite} />
      {composite ? (
        <Table striped bordered hover size="sm" className="w-75 mx-auto">
          <thead>
            <tr>
              <th>Behavior</th>
              <th className="text-center">Frequency</th>
            </tr>
          </thead>
          <tbody>{renderTable(tableData)}</tbody>
        </Table>
      ) : (
        <Table striped bordered hover size="sm" className="w-75 mx-auto">
          <tbody>
            <tr>
              <td>Talk Turn Count</td>
              <td className="text-center">{apiData.talk_turn_count}</td>
            </tr>
            <tr>
              <td>Utterance Count</td>
              <td className="text-center">{apiData.utterance_count}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

export default Counts;
