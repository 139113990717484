import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

const Suggestions = require('../assets/suggestions.png');
const SuggestionsShot = require('../assets/suggestions-shot.jpg');
const Insights  = require('../assets/insights.png');
const InsightsShot = require('../assets/insights-shot.jpg');
const Live = require('../assets/live.png');
const LiveShot = require('../assets/live-shot.jpg');
const Analytics  = require('../assets/analytics.png');
const AnalyticsShot = require('../assets/analytics-shot.jpg');
const MpathicBotShot = require('../assets/mpathicbot-shot.png');
const ApiShot = require('../assets/api-shot.png');

export default class Home extends Component {
  static displayName = Home.name;

  render() {

    return (
      <div>
        <section className='hero pa4 pt5-ns bg-white'>
          <div className='mw9 center pv4 pv5-ns pt6-l fadeUp'>
            <h1 className='mw7'>Accurate Understanding Starts with Empathy</h1>
            <p className='mw7'>Experience the future of emotion and clinical AI with mpathic's three powerful demos. </p>
          </div>
        </section>
        <section className='analytics bg-yellow pa4 overflow-hidden'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[80, 0]} opacity={[0,1]}>
                <img src={Analytics} alt='montage of a magnifying glass over a chart' />
              </Parallax>
              <h3>mpathic Analytics</h3>
              <p>Upload your transcript and view conversation analytics instantly.</p>
              <Link className='button' to='/analytics'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[140, -150]}>
                <Link to='/analytics'><img src={AnalyticsShot} alt='Analytics' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
        <section className='suggestions bg-near-white pa4 overflow-hidden'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[80, 0]} opacity={[0,1]}>
                <img src={Suggestions} alt='hand with pointing finger and chat bubbles' />
              </Parallax>
              <h3>mpathic Suggestions</h3>
              <p>Transform conversations and increase trust in real-time.</p>
              <Link className='button' to='/suggestions'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[140, -120]}>
                <Link to='/suggestions'><img src={SuggestionsShot} alt='Suggestions' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
        <section className='insights bg-yellow pa4 overflow-hidden'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[80, 0]} opacity={[0,1]}>
                <img src={Insights} alt='montage of light bulb, glasses and blue color burst' />
              </Parallax>
              <h3>mpathic Insights</h3>
              <p>Effortlessly view key analytics, highlights, and uncover potential improvements from past interactions.</p>
              <Link className='button' to='/insights'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[140, -150]}>
                <Link to='/insights'><img src={InsightsShot} alt='Insights' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
        <section className='live pa4 overflow-hidden bg-white'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[10, 0]} opacity={[0.5  ,1]}>
                <img src={Live} alt='Two computer screens facing each other' />
              </Parallax>
              <h3>mpathic Live</h3>
              <p>Benefit from timely suggestions, insightful behaviors, and useful tips that enhance your messaging.</p>
              <Link className='button' to='https://mpathic-live.sales.mpathicai.com/' target='_blank'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[100, -110]}>
                <Link to='https://mpathic-live.sales.mpathicai.com/' target='_blank'><img src={LiveShot} alt='Live' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
        <section className='insights bg-yellow pa4 overflow-hidden'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[10, 0]} opacity={[0,1]}>
                <img src={Insights} alt='montage of light bulb, glasses and blue color burst' />
              </Parallax>
              <h3>mpathic API</h3>
              <p>Learn how mpathic's API works by classifying constructs through our propietary models to identify behaviors..</p>
              <Link className='button' to='/classifications'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[140, -250]}>
                <Link to='/classifications'><img src={ApiShot} alt='API' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
        <section className='live pa4 overflow-hidden bg-white'>
          <div className='mw9 center flex flex-row flex-wrap items-center'>
            <div className='w-50-ns pr4 pr6-ns'>
              <Parallax translateY={[10, 0]} opacity={[0.5  ,1]}>
                <img src={Live} alt='Two computer screens facing each other' />
              </Parallax>
              <h3>mpathic Bot</h3>
              <p>Gain real-time insights from Google Meet, Zoom, WebEx, Microsoft Teams, and other meeting platforms..</p>
              <Link className='button' to='/mpathicbot'>Try out this demo <span className='icon-arrow-right'></span></Link>
            </div>
            <div className='w-50-ns pv4 pv6-ns tc'>
              <Parallax translateY={[100, -170]}>
                <Link to='/mpathicbot'><img src={MpathicBotShot} alt='Live' className='box-shadow rounded-lg' /></Link>
              </Parallax>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
