import React, { useState, ChangeEvent, useEffect } from "react";
import BotRadialBar from "./Charts/BotRadialBarChart";
import BotRadarChart from "./Charts/BotRadarChart";
import BotSankeyChart from "./Charts/BotSankeyChart";
import BotPieChart from "./Charts/BotPieChart";
import BotHighLows from "./BotHighLows";


const MpathicBotDashboard = (data: any) => {

    const participantBehaviorSeries = data.data
        ? data.data.analytics
            ? data.data.analytics.speaker_analytics.map((speaker: any) => {
                const { id, analytics } = speaker;
                const behaviorCounts = Object.entries(analytics.behavior_counts).map(([name, value]) => ({ x: name, y: value }));
                return {
                    id: id,
                    data: behaviorCounts
                }
            }) : [] : [];


    const conversationalProfileSeries = data.data
        ? data.data.analytics
            ? Object.entries(data.data.analytics.conversation_analytics.behavior_counts).map(([behavior, count]) => ({
                behavior,
                'count': count
            })) : [] : [];

    const links = data.data
        ? data.data.analytics
            ? data.data.analytics.synchrony_scores
                .filter((score: any) => score.synchrony_score !== null)
                .map((score: any) => ({
                    source: score.identifier_one,
                    target: score.identifier_two,
                    value: score.synchrony_score,
                }))
            : []
        : [];

    const nodes = links.length === 0
        ? [] // Return an empty array if links is empty
        : Array.from(
            new Set(
                links.flatMap(({ source, target }: { source: string, target: string }) => [source, target])
            )
        ).map((value) => ({ id: value }));


    const utteranceCountSeries = data.data
        ? data.data.analytics
            ? data.data.analytics.speaker_analytics.map((item: any) => ({
                id: item.id,
                label: item.id,
                value: item.analytics.utterance_count,
            })) : [] : [];

    return (
        <>
            <BotRadialBar data={participantBehaviorSeries} position={{ 'x': 5, 'y': 50 }} size={{ 'width': 855, 'height': 655 }} title={'Participant Behaviors'} />
            <BotRadarChart data={conversationalProfileSeries} position={{ 'x': 865, 'y': 50 }} size={{ 'width': 450, 'height': 430 }} title={'Conversation Profile'} />
            <BotSankeyChart data={{ nodes, links }} position={{ 'x': 5, 'y': 710 }} size={{ 'width': 855, 'height': 245 }} title={'Synchrony'} />
            <BotPieChart data={utteranceCountSeries} position={{ 'x': 865, 'y': 485 }} size={{ 'width': 450, 'height': 470 }} title={'Talk Time'} />
            <BotHighLows data={data} position={{'x': 1320, 'y': 50}} size={{ 'width': 480, 'height': 905 }} title={'Feedback'} />
        </>
    )
};

export default MpathicBotDashboard;

