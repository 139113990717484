import React from "react";

interface IProps {
  className: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<any>;
}

const MessageCard: React.FC<IProps> = ({ className, children, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default MessageCard;
