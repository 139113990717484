import React, { Component } from 'react';

export class Behaviors extends Component {
  static displayName = Behaviors.name;

  constructor(props) {
    super(props);
    this.state = { behaviors: [], loading: true };
  }

  componentDidMount() {
    this.fetchBehaviors();
  }

  static renderBehaviors(behaviors) {
    return (
      <div>
        <pre>
          {JSON.stringify(behaviors, null, 2)}
        </pre>
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : Behaviors.renderBehaviors(this.state.behaviors);

    return (
      <div>
        <h1 id="tableLabel">Behaviors</h1>
        <p>This component demonstrates fetching behaviors from the mpathic API.</p>
        {contents}
      </div>
    );
  }

  async fetchBehaviors() {
    const rawResponse = await fetch('mpathicApi/v1/behaviors', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        input: 'Thanks for working on the sales demos!',
        include_suggestions: true
      })
    });
    const content = await rawResponse.json();
    this.setState({ behaviors: content, loading: false });
  }
}
