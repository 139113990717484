import React from 'react';
import { Rnd } from "react-rnd";
import { ResponsiveSankey } from '@nivo/sankey'

import { botCustomChartColors, botRndStyle } from '../../helpers/botCommon';

interface BotSankeyChartProps {
    data: any;
    position: any;
    size: any;
    title: string;
}

const BotSankeyChart: React.FC<BotSankeyChartProps> = ({ data, position, size, title }) => {
    return (
        <>
            <Rnd
                style={botRndStyle}
                default={{
                    x: position.x,
                    y: position.y,
                    width: size.width,
                    height: size.height,
                }}>
                <div style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", textAlign: "center", width: "100%" }}>{title}</div>
                {data.nodes.length > 0 ? (
                <ResponsiveSankey
                    data={data}
                    margin={{ top: 40, right: 120, bottom: 40, left: 120 }}
                    align="justify"
                    colors={botCustomChartColors}
                    nodeOpacity={1}
                    nodeHoverOthersOpacity={0.35}
                    nodeThickness={18}
                    nodeSpacing={24}
                    nodeBorderWidth={0}
                    nodeBorderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.8
                            ]
                        ]
                    }}
                    nodeBorderRadius={3}
                    linkOpacity={0.5}
                    linkHoverOthersOpacity={0.1}
                    linkContract={3}
                    enableLinkGradient={true}
                    labelPosition="outside"
                    labelOrientation="horizontal"
                    labelPadding={16}
                    labelTextColor={"#000"}
                />
                ) : <></>}
            </Rnd>
        </>
    )
}

export default BotSankeyChart;