import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { Rnd } from "react-rnd";

import { botCustomChartColors, botRndStyle } from '../../helpers/botCommon';

interface BotRadarBarProps {
    data: any;
    position: any;
    size: any;
    title: string;
}

const BotRadarChart: React.FC<BotRadarBarProps> = ({ data, position, size, title }) => {
    return (
        <>
            <Rnd
                style={botRndStyle}
                default={({
                    x: position.x,
                    y: position.y,
                    width: size.width,
                    height: size.height
                })}
                >
                <div style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", textAlign: "center", width: "100%" }}>{title}</div>
                <ResponsiveRadar
                    data={data}
                    colors={botCustomChartColors}
                    keys={['count']}
                    indexBy="behavior"
                    margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                    borderColor={{ from: 'color' }}
                    gridLabelOffset={10}
                    blendMode="multiply"
                    motionConfig="wobbly"
                    curve="cardinalClosed"
                    enableDots={false}
                    legends={[
                        {

                            anchor: 'bottom-right',
                            direction: 'column',
                            translateX: -50,
                            translateY: -40,
                            itemWidth: 0,
                            itemHeight: 0,
                            itemTextColor: '#999',
                            symbolSize: 0,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Rnd>
        </>
    )
};

export default BotRadarChart;