import React from "react";

interface IProps {
  kind: any;
}
const icon = ({ kind }: IProps) => {
  switch (kind) {
    case "arrowDownIcon":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2828 4.89817">
          <path d="M4.64116,4.89817a.5001.5001,0,0,1-.34277-.13574L.15727.86448A.50018.50018,0,0,1,.84282.136L4.64116,3.71165,8.44.136a.50018.50018,0,0,1,.68555.72852L4.98393,4.76243A.5001.5001,0,0,1,4.64116,4.89817Z"></path>
        </svg>
      );
    case "send":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M11.4799 16.2L16.2533 11.4133M7.86662 6.42666L19.1866 2.65333C24.2666 0.959992 27.0266 3.73332 25.3466 8.81332L21.5733 20.1333C19.0399 27.7467 14.8799 27.7467 12.3466 20.1333L11.2266 16.7733L7.86662 15.6533C0.253283 13.12 0.253283 8.97333 7.86662 6.42666V6.42666Z"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "settingCyan":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20V20Z"
            stroke="#fd00bf"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.66666 17.1733V14.8267C2.66666 13.44 3.8 12.2933 5.2 12.2933C7.61333 12.2933 8.6 10.5867 7.38666 8.49333C6.69333 7.29333 7.10666 5.73333 8.32 5.04L10.6267 3.72C11.68 3.09333 13.04 3.46666 13.6667 4.52L13.8133 4.77333C15.0133 6.86666 16.9867 6.86666 18.2 4.77333L18.3467 4.52C18.9733 3.46666 20.3333 3.09333 21.3867 3.72L23.6933 5.04C24.9067 5.73333 25.32 7.29333 24.6267 8.49333C23.4133 10.5867 24.4 12.2933 26.8133 12.2933C28.2 12.2933 29.3467 13.4267 29.3467 14.8267V17.1733C29.3467 18.56 28.2133 19.7067 26.8133 19.7067C24.4 19.7067 23.4133 21.4133 24.6267 23.5067C25.32 24.72 24.9067 26.2667 23.6933 26.96L21.3867 28.28C20.3333 28.9067 18.9733 28.5333 18.3467 27.48L18.2 27.2267C17 25.1333 15.0267 25.1333 13.8133 27.2267L13.6667 27.48C13.04 28.5333 11.68 28.9067 10.6267 28.28L8.32 26.96C7.7388 26.6253 7.31411 26.0738 7.13912 25.4264C6.96414 24.7789 7.05317 24.0885 7.38666 23.5067C8.6 21.4133 7.61333 19.7067 5.2 19.7067C3.8 19.7067 2.66666 18.56 2.66666 17.1733V17.1733Z"
            stroke="#fd00bf"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "settingWhite":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16C20 14.9391 19.5786 13.9217 18.8284 13.1716C18.0783 12.4214 17.0609 12 16 12C14.9391 12 13.9217 12.4214 13.1716 13.1716C12.4214 13.9217 12 14.9391 12 16C12 17.0609 12.4214 18.0783 13.1716 18.8284C13.9217 19.5786 14.9391 20 16 20V20Z"
            stroke="white"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.66675 17.1733V14.8267C2.66675 13.44 3.80008 12.2933 5.20008 12.2933C7.61341 12.2933 8.60008 10.5867 7.38675 8.49333C6.69341 7.29333 7.10675 5.73333 8.32008 5.04L10.6267 3.72C11.6801 3.09333 13.0401 3.46666 13.6667 4.52L13.8134 4.77333C15.0134 6.86666 16.9867 6.86666 18.2001 4.77333L18.3467 4.52C18.9734 3.46666 20.3334 3.09333 21.3867 3.72L23.6934 5.04C24.9067 5.73333 25.3201 7.29333 24.6267 8.49333C23.4134 10.5867 24.4001 12.2933 26.8134 12.2933C28.2001 12.2933 29.3467 13.4267 29.3467 14.8267V17.1733C29.3467 18.56 28.2134 19.7067 26.8134 19.7067C24.4001 19.7067 23.4134 21.4133 24.6267 23.5067C25.3201 24.72 24.9067 26.2667 23.6934 26.96L21.3867 28.28C20.3334 28.9067 18.9734 28.5333 18.3467 27.48L18.2001 27.2267C17.0001 25.1333 15.0267 25.1333 13.8134 27.2267L13.6667 27.48C13.0401 28.5333 11.6801 28.9067 10.6267 28.28L8.32008 26.96C7.73888 26.6253 7.31419 26.0738 7.13921 25.4264C6.96423 24.7789 7.05325 24.0885 7.38675 23.5067C8.60008 21.4133 7.61341 19.7067 5.20008 19.7067C3.80008 19.7067 2.66675 18.56 2.66675 17.1733V17.1733Z"
            stroke="white"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "arrowRight":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="20"
          viewBox="0 0 31 20"
          fill="none"
        >
          <path
            d="M2 8.75C1.30964 8.75 0.75 9.30964 0.75 10C0.75 10.6904 1.30964 11.25 2 11.25L2 8.75ZM29.8839 10.8839C30.372 10.3957 30.372 9.60427 29.8839 9.11611L21.9289 1.16116C21.4408 0.673008 20.6493 0.673008 20.1612 1.16116C19.673 1.64932 19.673 2.44078 20.1612 2.92893L27.2322 10L20.1612 17.0711C19.673 17.5592 19.673 18.3507 20.1612 18.8388C20.6493 19.327 21.4408 19.327 21.9289 18.8388L29.8839 10.8839ZM2 11.25L29 11.25L29 8.75L2 8.75L2 11.25Z"
            fill="#fd00bf"
          />
        </svg>
      );
    case "close":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18.0104 6.01074L5.98962 18.0316"
            stroke="#000000"
            strokeWidth="2.5"
            strokeLinecap="round"
          />
          <path
            d="M18.0104 18.0312L5.98962 6.01043"
            stroke="#000000"
            strokeWidth="2.5"
            strokeLinecap="round"
          />
        </svg>
      );
    case "info":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.9996 18.3333C5.39702 18.3333 1.66667 14.6027 1.66667 9.99979C1.66667 5.39742 5.39698 1.66667 9.9996 1.66667C14.6035 1.66667 18.3333 5.39742 18.3333 9.99979C18.3333 14.6026 14.6034 18.3333 9.9996 18.3333ZM1.0331e-06 9.99979C1.51596e-06 15.523 4.47642 20 9.9996 20C15.524 20 20 15.523 20 9.99979C20 4.47698 15.524 3.91304e-07 9.9996 8.74262e-07C4.47642 1.35711e-06 5.50287e-07 4.47703 1.0331e-06 9.99979ZM10.8332 9.79167C10.8332 9.21637 10.3668 8.75 9.79154 8.75C9.21624 8.75 8.74987 9.21637 8.74987 9.79167L8.74987 13.9583C8.74987 14.5336 9.21624 15 9.79154 15C10.3668 15 10.8332 14.5336 10.8332 13.9583L10.8332 9.79167ZM10.8332 6.45833C10.8332 5.88303 10.3668 5.41666 9.79154 5.41666C9.21624 5.41666 8.74987 5.88303 8.74987 6.45833C8.74987 7.03363 9.21624 7.5 9.79154 7.5C10.3668 7.5 10.8332 7.03363 10.8332 6.45833Z"
            fill="#5C5C5C"
          />
        </svg>
      );
    case "check":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4327 0.733805C19.8558 1.15687 19.8558 1.8428 19.4327 2.26587L7.51603 14.1825C7.09296 14.6056 6.40704 14.6056 5.98397 14.1825L0.567301 8.76587C0.144233 8.3428 0.144233 7.65687 0.567301 7.2338C0.990369 6.81074 1.6763 6.81074 2.09937 7.2338L6.75 11.8844L17.9006 0.733805C18.3237 0.310737 19.0096 0.310737 19.4327 0.733805Z"
            fill="#fd00bf"
          />
        </svg>
      );
    case "check1":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4327 0.733965C19.8558 1.15703 19.8558 1.84296 19.4327 2.26603L7.51603 14.1827C7.09296 14.6058 6.40704 14.6058 5.98397 14.1827L0.567301 8.76603C0.144233 8.34296 0.144233 7.65703 0.567301 7.23396C0.990369 6.8109 1.6763 6.8109 2.09937 7.23396L6.75 11.8846L17.9006 0.733965C18.3237 0.310897 19.0096 0.310897 19.4327 0.733965Z"
            fill="white"
          />
        </svg>
      );
    case "thumbdown":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
        </svg>
      );
    case "thumbup":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
        </svg>
      );
    case "bold":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <desc></desc>
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z"></path>
          <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7"></path>
        </svg>
      );
    case "italic":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <desc></desc>
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="11" y1="5" x2="17" y2="5"></line>
          <line x1="7" y1="19" x2="13" y2="19"></line>
          <line x1="14" y1="5" x2="10" y2="19"></line>
        </svg>
      );
    case "quotesRight":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
          </g>
        </svg>
      );
    case "quotesLeft":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.5-2.457 6.637-6.03 8.188l-.893-1.378c3.335-1.804 3.987-4.145 4.247-5.621-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 0 1 3.5-3.5c1.073 0 2.099.49 2.748 1.179z"></path>
          </g>
        </svg>
      );
    case "fontIcon":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m11.307 4-6 16h2.137l1.875-5h6.363l1.875 5h2.137l-6-16h-2.387zm-1.239 9L12.5 6.515 14.932 13h-4.864z"></path>
        </svg>
      );
    case "leftArrow":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0z" />
        </svg>
      );
    case "iconClose":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={12}
          height={12}
          fill="#333"
        >
          <path d="M4.707 3.293 3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z" />
        </svg>
      );
    case "minimize":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 16 16"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 8v1H3V8h11z"></path>
        </svg>
      );
    case "dismiss":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
            fill="currentColor"
          ></path>
        </svg>
      );
    case "maxMize":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="15 3 21 3 21 9"></polyline>
          <polyline points="9 21 3 21 3 15"></polyline>
          <line x1="21" y1="3" x2="14" y2="10"></line>
          <line x1="3" y1="21" x2="10" y2="14"></line>
        </svg>
      );
    case "delete":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="3 6 5 6 21 6"></polyline>
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          <line x1="10" y1="11" x2="10" y2="17"></line>
          <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
      );
    case "font":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8zm-650.3-80h85c4.2 0 8-2.7 9.3-6.8l53.7-166h219.2l53.2 166c1.3 4 5 6.8 9.3 6.8h89.1c1.1 0 2.2-.2 3.2-.5a9.7 9.7 0 0 0 6-12.4L573.6 118.6a9.9 9.9 0 0 0-9.2-6.6H462.1c-4.2 0-7.9 2.6-9.2 6.6L244.5 723.1c-.4 1-.5 2.1-.5 3.2-.1 5.3 4.3 9.7 9.7 9.7zm255.9-516.1h4.1l83.8 263.8H424.9l84.7-263.8z"></path>
        </svg>
      );
    case "attach":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M341.334 128v234.666C341.334 409.604 302.938 448 256 448c-46.937 0-85.333-38.396-85.333-85.334V117.334C170.667 87.469 194.135 64 224 64c29.864 0 53.333 23.469 53.333 53.334v245.333c0 11.729-9.605 21.333-21.334 21.333s-21.333-9.604-21.333-21.333V160h-32v202.667C202.667 392.531 226.135 416 256 416c29.865 0 53.334-23.469 53.334-53.333V117.334C309.334 70.401 270.938 32 224 32s-85.334 38.401-85.334 85.334v245.332C138.667 427.729 190.938 480 256 480c65.062 0 117.334-52.271 117.334-117.334V128h-32z"></path>
        </svg>
      );
    case "link":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
          <line x1="8" y1="12" x2="16" y2="12"></line>
        </svg>
      );
    case "smile":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
          <path d="M14.829 14.828a4.055 4.055 0 0 1-1.272.858 4.002 4.002 0 0 1-4.875-1.45l-1.658 1.119a6.063 6.063 0 0 0 1.621 1.62 5.963 5.963 0 0 0 2.148.903 6.042 6.042 0 0 0 2.415 0 5.972 5.972 0 0 0 2.148-.903c.313-.212.612-.458.886-.731.272-.271.52-.571.734-.889l-1.658-1.119a4.017 4.017 0 0 1-.489.592z"></path>
          <circle cx="8.5" cy="10.5" r="1.5"></circle>
          <circle cx="15.493" cy="10.493" r="1.493"></circle>
        </svg>
      );
    case "drive":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              fill-rule="nonzero"
              d="M9.097 6.15L4.31 14.443l1.755 3.032 4.785-8.29L9.097 6.15zm-1.3 12.324h9.568l1.751-3.034H9.55l-1.752 3.034zm11.314-5.034l-4.786-8.29H10.83l4.787 8.29h3.495zM8.52 3.15h6.96L22 14.444l-3.48 6.03H5.49L2 14.444 8.52 3.15zm3.485 8.036l-1.302 2.254h2.603l-1.301-2.254z"
            ></path>
          </g>
        </svg>
      );
    case "image":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 3H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19V5h14l.002 14H5z"></path>
          <path d="m10 14-1-1-3 4h12l-5-7z"></path>
        </svg>
      );
    case "security":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path d="M14.5 14.2l2.9 1.7-.8 1.3L13 15v-5h1.5v4.2zM22 14c0 4.41-3.59 8-8 8-2.02 0-3.86-.76-5.27-2H4c-1.15 0-2-.85-2-2V9c0-1.12.89-1.96 2-2v-.5C4 4.01 6.01 2 8.5 2c2.34 0 4.24 1.79 4.46 4.08.34-.05.69-.08 1.04-.08 4.41 0 8 3.59 8 8zM6 7h5v-.74A2.509 2.509 0 008.5 4 2.5 2.5 0 006 6.5V7zm14 7c0-3.31-2.69-6-6-6s-6 2.69-6 6 2.69 6 6 6 6-2.69 6-6z"></path>
        </svg>
      );
    case "pen":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M17.849 11.808l-.707-.707-9.9 9.9H3v-4.243L14.313 5.444l5.657 5.657a1 1 0 0 1 0 1.414l-7.07 7.071-1.415-1.414 6.364-6.364zm.707-9.192l2.829 2.828a1 1 0 0 1 0 1.414L19.97 8.273 15.728 4.03l1.414-1.414a1 1 0 0 1 1.414 0z"></path>
          </g>
        </svg>
      );
    case "more":
      return (
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
        </svg>
      );
    case "chevron":
      return (
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      );
    case "copyText":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#000"
          version="1.1"
          viewBox="0 0 64 64"
          xmlSpace="preserve"
        >
          <g>
            <path d="M53.98 9.143h-3.97c-.082 0-.155.028-.232.047V5.023C49.778 2.253 47.473 0 44.64 0H10.217C7.384 0 5.08 2.253 5.08 5.023v46.843c0 2.77 2.305 5.023 5.138 5.023h6.037v2.268c0 2.67 2.216 4.843 4.941 4.843H53.98c2.725 0 4.942-2.173 4.942-4.843v-45.17c0-2.671-2.217-4.844-4.942-4.844zM7.11 51.866V5.023c0-1.649 1.394-2.991 3.106-2.991H44.64c1.712 0 3.106 1.342 3.106 2.99v46.844c0 1.649-1.394 2.991-3.106 2.991H10.217c-1.712 0-3.106-1.342-3.106-2.99zm49.778 7.29c0 1.551-1.306 2.812-2.91 2.812H21.195c-1.604 0-2.91-1.26-2.91-2.811v-2.268H44.64c2.833 0 5.138-2.253 5.138-5.023V11.128c.077.018.15.047.233.047h3.968c1.604 0 2.91 1.26 2.91 2.811v45.17z"></path>
            <path d="M38.603 13.206H16.254a1.015 1.015 0 100 2.032h22.35a1.015 1.015 0 100-2.032zM38.603 21.333H16.254a1.015 1.015 0 100 2.032h22.35a1.015 1.015 0 100-2.032zM38.603 29.46H16.254a1.015 1.015 0 100 2.032h22.35a1.015 1.015 0 100-2.032zM28.444 37.587h-12.19a1.015 1.015 0 100 2.032h12.19a1.015 1.015 0 100-2.032z"></path>
          </g>
        </svg>
      );
    default:
      return null;
  }
};

export default icon;
