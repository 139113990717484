var sanitizeHtml = require("sanitize-html");
export function cleanHTML(data: any) {
  var clean = sanitizeHtml(data, {
    allowedTags: [],
    allowedAttributes: {},
  });
  return clean;
}

export function sanitize(data: string) {
  let sanitizedData = data.replace(/(<([^>]+)>)/gi, ""); //.trim();
  // eslint-disable-next-line
  sanitizedData = sanitizedData.replace(/\&nbsp;/g, " ");
  return sanitizedData;
}

export function highlightText(userdata: string, highlight: string) {
  let sanitizeData = cleanHTML(userdata);
  var index = sanitizeData.indexOf(highlight);
  if (index >= 0) {
    sanitizeData =
      sanitizeData.substring(0, index) +
      "<span class='highlight'>" +
      sanitizeData.substring(index, index + highlight.length) +
      "</span>" +
      sanitizeData.substring(index + highlight.length);
  }
  return sanitizeData;
}


export function acceptText(sanitizeData: string, findData: string, acceptText: string) {
  sanitizeData = cleanHTML(sanitizeData);
  sanitizeData = sanitizeData.replace(findData, acceptText);
  return sanitizeData;
}

export function countWords(data: string) {
  let count = 0;
  let words = data.split(" ");
  for (var i = 0; i < words.length; i++) {
    if (words[i] !== "") {
      count += 1;
    }
  }
  return count;
}
