import React, { Component } from 'react';

export class ChatCompletion extends Component {
  static displayName = ChatCompletion.name;

  constructor(props) {
    super(props);
    this.state = { completion: [], loading: true };
  }

  componentDidMount() {
    this.fetchChatCompletion();
  }

  static renderChatCompletion(completion) {
    return (
      <div>
        <pre>
          {JSON.stringify(completion, null, 2)}
        </pre>
      </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : ChatCompletion.renderChatCompletion(this.state.completion);

    return (
      <div>
        <h1 id="tableLabel">ChatCompletion</h1>
        <p>This component demonstrates fetching a chat completion from Open AI.</p>
        {contents}
      </div>
    );
  }

  async fetchChatCompletion() {
    const rawResponse = await fetch('api/chatCompletion', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        model: "gpt-4",
        temperature: 0.7,
        frequency_penalty: 0,
        max_tokens: 2048,
        messages: [
          {
            role: "system",
            content: "Improve the empathy of the following user message. If the message is already empathetic, give an encouragement instead."
          },
          {
            role: "user",
            content: "Are you going to start helping now?"
          }
        ]
      })
    });
    const content = await rawResponse.json();
    this.setState({ completion: content, loading: false });
  }
}
