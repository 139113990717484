import React from "react";

interface IProps {
  className: string;
  children: React.ReactNode;
}

const Card: React.FC<IProps> = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

export default Card;
