import Button from "../components/base/Button";
import { useNavigate } from "react-router-dom";
import {
  healthcareHighlights,
  healthcareLowlights,
  healthcareTalkTurns,
  hiringHighlights,
  hiringLowlights,
  hiringTalkTurns,
  InsightsHealtcareApi,
  InsightshiringApi,
  InsightsOneToOneApi,
  InsightsSalesApi,
  oneToOneHighlights,
  oneToOneLowlights,
  oneToOneTalkTurns,
  salesHighlights,
  salesLowlights,
  salesTalkTurns,
} from "../data/insightsApi";
const insights =  require("../assets/insights.png");

const Insights = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mw9 center insight-container pb4 pb5-ns">
        <div className="insights-body-wrapper">
          <div className="insight-header-center pa4">
            <div className="breadcrumbs f6">
              <a href="/">Home</a> &nbsp; &gt; &nbsp; 
              <span>Insights</span>
            </div>
            <div className="flex flex-row-ns flex-column items-center-ns items-start pv4">
              <div className="header-img">
                <img src={insights} alt="realtime" />
              </div>
              <div className="header-body ph4">
                <h1>mpathic Insights</h1>
                <p>
                  mpathic has encoded the science of empathy to identify
                  specific conversation behaviors that create connection. Know
                  exactly what to say to build empathy and trust.
                </p>
              </div>
            </div>
          </div>

          <div className="tc pa4 ph5-ns pb5-ns bg-white box-shadow br4">
            <h4>What conversation do you want to improve?</h4>
            <div className="mw7 center flex flex-row flex-wrap justify-around">
              <Button
                className="insight-home-btn mb3"
                onClick={() =>
                  navigate("/insights/1:1 meeting", {
                    state: {
                      title: "1:1 Meeting",
                      talk_turns: oneToOneTalkTurns,
                      highlights: oneToOneHighlights,
                      lowlights: oneToOneLowlights,
                      apiData: InsightsOneToOneApi,
                    },
                  })
                }
              >
                1:1 Meeting
              </Button>
              <Button
                className="insight-home-btn mb3"
                onClick={() =>
                  navigate("/insights/hiringinterview", {
                    state: {
                      title: "Interview",
                      talk_turns: hiringTalkTurns,
                      highlights: hiringHighlights,
                      lowlights: hiringLowlights,
                      apiData: InsightshiringApi,
                    },
                  })
                }
              >
                Interview
              </Button>
              <Button
                className="insight-home-btn mb3"
                onClick={() =>
                  navigate("/insights/healthcare", {
                    state: {
                      title: "Doctors Appointment",
                      talk_turns: healthcareTalkTurns,
                      highlights: healthcareHighlights,
                      lowlights: healthcareLowlights,
                      apiData: InsightsHealtcareApi,
                    },
                  })
                }
              >
                Doctor's Appointment
              </Button>
              <Button
                className="insight-home-btn mb3"
                onClick={() =>
                  navigate("/insights/sales", {
                    state: {
                      title: "Sales Meeting",
                      talk_turns: salesTalkTurns,
                      highlights: salesHighlights,
                      lowlights: salesLowlights,
                      apiData: InsightsSalesApi,
                    },
                  })
                }
              >
                Sales Meeting
              </Button>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Insights;
