import Home from "./pages/Home";
import Insights from "./pages/Insights";
import Suggestions from "./pages/Suggestions";
import Analytics from "./pages/Analytics";
import { Behaviors } from "./components/Behaviors";
import { ChatCompletion } from "./components/ChatCompletion";
import { Conversations } from "./components/Conversations";
import { Unauthorized } from "./components/Unauthorized";
import Meeting from "./components/Meeting";
import Upload from "./components/Upload";
import Classifications from "./pages/Classifications";
import MpathicBot from "./pages/MpathicBot";

const AppRoutes = [
  {
    index: true,
    requireAuth: true,
    element: <Home />
  },
  {
    path: '/suggestions',
    requireAuth: true,
    element: <Suggestions />
  },
  {
    path: '/analytics',
    requireAuth: true,
    element: <Analytics />
  },
  {
    path: '/insights',
    requireAuth: true,
    element: <Insights />
  },
  {
    path: '/insights/1:1 meeting',
    requireAuth: true,
    element: <Meeting />
  },
  {
    path: '/insights/hiringinterview',
    requireAuth: true,
    element: <Meeting />
  },
  {
    path: '/insights/healthcare',
    requireAuth: true,
    element: <Meeting />
  },
  {
    path: '/insights/sales',
    requireAuth: true,
    element: <Meeting />
  },
  {
    path: '/insights/upload',
    requireAuth: true,
    element: <Upload />
  },
  {
    path: '/behaviors',
    requireAuth: true,
    element: <Behaviors />
  },
  {
    path: '/conversations',
    requireAuth: true,
    element: <Conversations />
  },
  {
    path: '/chatCompletion',
    requireAuth: true,
    element: <ChatCompletion />
  },
  {
    path: '/unauthorized',
    requireAuth: false,
    element: <Unauthorized />
  },
  {
    path: '/classifications',
    requireAuth: true,
    element: <Classifications />
  },
  {
  path: '/mpathicbot',
  requireAuth: true,
  element: <MpathicBot />
  }
];

export default AppRoutes;
