import React, { useState } from "react";
import Button from "../components/base/Button";
import Card from "../components/base/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { getInsightsAPI } from "../api/api-common";
import LoadingAnimation from "./base/LoadingAnimation";
import MpathicStatsCard from "../components/MpathicStatsCard";
const insights = require("../assets/insights.png");

const Meeting = () => {
  const location = useLocation();
  const [insightActive, setInsightActive] = useState<boolean>(false);
  const [responseMetrics, setResponseMetrics] = useState({});
  const [synchronyMetrics, setSynchronyMetrics] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleInsights = () => {
    if (insightActive) {
      setInsightActive(false);
    } else {
      setIsLoading(true);
      getInsightsAPI(location.state.talk_turns)
        .then((res) => {
          setResponseMetrics(
            res && res.data.analytics.conversation_analytics.metrics
          );
          setSynchronyMetrics(res && res.data.analytics.synchrony_scores);
          setInsightActive(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err);
          console.log(err);
        });
    }
  };

  return (
    <div className="insight-container">
      {isLoading && <LoadingAnimation />}

      <div className="flex flex-row flex-wrap">
        <div className="insight-meeting-left w-20-l pa4">
          <div className="breadcrumbs f6">
            <a href="/">Home</a> &nbsp; &gt; &nbsp;
            <a href="/insights">Insights</a> &nbsp; &gt; &nbsp;
            <span>{location.state.title}</span>
          </div>

          <div className="flex flex-column flex-row-m flex-column-l justify-between items-start items-center-m pt4 pv4-l">
            <div>
              <img src={insights} alt="realtime" />
            </div>
            <div>
              <h3>
                mpathic Insights
                <br />
                <span className="normal">
                  {">"} {location.state.title}
                </span>
              </h3>
            </div>
            <div className="insight-meeting-buttons">
              {location.state.title !== "Hiring Interview" ? (
                <Button
                  className="btn primary-btn meeting-btn mb3"
                  onClick={() => navigate("/insights")}
                >
                  Try a different insight
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="insight-meeting-center w-50-m w-40-l w-50-xl pa4 pt5-l">
          <div className="vh-25 vh-75-ns overflow-auto bg-white br4 pa4 pa5-l box-shadow">
            <div>
              <Card
                className={`card transcript-window ${
                  insightActive ? "" : "insightActive"
                }`}
              >
                <div
                  className="message-container"
                  contentEditable="true" 
                  suppressContentEditableWarning={true}
                >
                  {location.state.talk_turns.map((talk: any, i: any) => (
                    <div className="transcript-message pb3" key={i}>
                      <div className="message">
                        <span className="user b">{talk.speaker.id}:</span>
                        <span> {talk.input}</span>
                      </div>
                      <div className="time i f6">{talk.timestamp.start}</div>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div className="insight-meeting-right w-50-m w-40-l w-30-xl pa4 pa2-m pt5-l">
          <div className="flex flex-row flex-wrap mb3">
            <Button
              className={`${insightActive ? "active" : ""} mr3 mb3`}
              onClick={handleInsights}
            >
              {insightActive ? "Hide" : "Show"} mpathic Insights
            </Button>
          </div>

          {insightActive && (
            <div
              className={`vh-70 overflow-auto apicard-wrapper ${
                insightActive ? "active" : ""
              }`}
            >
              <MpathicStatsCard
                statsData={responseMetrics}
                synchronyData={synchronyMetrics}
                highlightsData={location.state.highlights}
                lowLightsData={location.state.lowlights}
              />
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default Meeting;
