import React from "react";
import { Tooltip } from "react-tooltip";

interface IProps {
  currentValue: number;
  baseColor: string;
  completedColor: string;
  title: string;
  description: string;
}

const InsightCircularbar: React.FC<IProps> = ({
  title,
  completedColor,
  baseColor,
  currentValue,
  description,
}) => {
  return (
    <div className="cicularbar-card">
      <Tooltip anchorSelect={`#${title}`} className="tooltip-container" clickable/>
      <div className="circular-header">
        <h6 className="insightbar-title b">{title}</h6>
        <span id={title} data-tooltip-content={description} className="tooltip">
          i
        </span>
      </div>
      <div className="bar-wrappee">
        <div
          className="circular-bar"
          style={{
            background: `conic-gradient(${completedColor} ${
              36 * currentValue
            }deg, ${baseColor} 0)`,
          }}
        >
          <div className="count">
            {currentValue <= 0 ? (
              <span style={{ fontSize: "1.5rem" }}>Low Data</span>
            ) : (
              currentValue
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightCircularbar;
