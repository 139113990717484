export const defaultAnalyticsConversation = {
        "conversation_id": "Demo",
        "include_conversation": true,
        "talk_turns": [
        {
            "input": "Yeah.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Good. Can you hear me? Yeah, loud and clear.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Great. Sorry, my Internet was being a little bit delayed.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay, no worries. I actually am not done with my lunch yet, actually. So I've got a bowl here.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Depending on what we work on, I may go off camera so I can keep eating messily.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yes, please go ahead and eat. Yeah, I'd love to it. Get your feedback and hear what exactly you need for the COo.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So I just want to start by saying I'm really excited about the opportunity to partner with empathic.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I think what you're doing is fantastic, and I'm sure you have lots of other amazing opportunities that you've alluded to in our previous conversations that are in that deck, et cetera. And I don't know where we fit into that.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Your sort of priority schema as a small team, small band of rebels about to disrupt the entire world as we know it.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "There can be a lot going on at any given time.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "But I just want to say up front, very openly, I'm kind of playing dumb with you intentionally, because if I don't play dumb with you, then I'm stuck trying to figure out how the heck do I explain this to my colleagues?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, you need to have more assets and resources to explain this, and I want to make sure that you have everything that you need to make the sale.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I think it's hard without knowing exactly how we're going to work together to get to that concrete way.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So I asked you to put the ask in the deck. Right.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And I thought that was a pretty straightforward question, but apparently it wasn't, because what you sent me was pricing for using the product.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And when we started talking, I thought we talked about a different kind of ask, which is what I thought you would come back to me.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I just figured we'd just meet today and talk about this, which is, you wanted some sessions from us, right?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Recorded sessions from us, yeah.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And what I'm looking for is.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Well, okay, let's be really specific about what do you need? Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Let me propose kind of a schema for communicating to my colleagues.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So, what do you need? And I can answer any clarifying questions about what we have, because I know it's kind of a chicken and egg thing, like, well, what do you have? What do you need? What do you have?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Right. So, to get us out of know if there are other questions need to ask about, what do we, you know, out of that should come. Well, based on what you have, Chris, this is what we need of that.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "In order to do step b, in step b, we'd do this, and then you would get some output c from that, and then you'd have a decision to make about step d, which is to use the product to do e and finafile.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Sure. So a lot of that, to me, aligns with what we outlined in the statement of work in Loi, where we had kind of left.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "That was that these were kind of the things that the company, as an empathic would provide, like an NDA, the letter of.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Go back. Let's go down to step six. Okay, that's my a.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "My a is sample role play data through empathic API and produce a.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That, and then b happens, what behaviors were detected.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So sample role play.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "You know what? My dog's being very patient. I'm going to let him out.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "It's a nice sunny day.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "You can play outside, young man.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "All right, so what we have that would be relatively easy for us to provide, I believe, is our recorded role playing sessions, which have been deposited on our drives by aspiring aspirants to fidelity in the family checkup model.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And they have already been rated by our team using our existing evidence based.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Allegedly evidence based rating.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "This I just want to draw attention to.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "This is where David Atkins and who's the university of Utah guy again?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Zach. You know him as well. Well, Zach, but there is another guy there. Shoot, what's his name?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Anyway, their collaboration, they ran across this problem of consistency of the human. Right. So, yeah, so Atkins apparently tried to do sort of AI coding of sessions using the family checkup, modeling those to humans.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And one of the things that was learned was that the humans were inconsistent in their.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So that's potentially problematic.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Right. And I want to just draw attention to that right up front.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "But we can do that, and maybe we'll get to something that can inform our development of our own human rating system. Frankly, I could see it going the.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Other way right before we keep going.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "It sounds like a lot of the things that as far as, like, the ask and get would be best worked through on this document, because things that I will need are some of the stuff that's already on here.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So, for example, fidelity radio manual, if you have rated sessions, that's in here as well.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So it might be best if we just work through this together in a step by step fashion.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But is this the kind of thing that you're saying your coo needs? Okay, but you didn't show this to your coo?",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "No, not yet.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "You still have access to this, doc, I want to just make sure that you can get in here and work on it with.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "It's a Google Doc, right?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah. Let me add you to.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I think I just sent it to you as an attachment previously.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay, you can actually just get in here with me.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Oh, I can't be shared.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I think I sent it to you as an attachment.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I'm not finding it on my Google Docs.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That's okay. I will just get this into a file for you and then we can collaborate on it together.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah. And I will also put my Gmail account.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Oh, perfect.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "That'll make it a lot easier in the chat there.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And what you're talking about with human raiders, what we like to do is when we get a data sample and when we'll look at your framework, we can look at the inner reliability between your raters as well as ours.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So before we build models, that's kind of our first step is to see can humans rate things? Because a human can't rate it, you can't build a model off of it, period.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Right.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "You're welcome to come in here and if you want to get into more specifics about what we're going to provide to you and then what you want to provide back in a stepwise fashion, I think that's going to be the best way to talk about relationship look.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But the idea with this really is that until we get that sample role play and until we see what the fidelity radiant system is, we won't necessarily be able to propose.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "The goal for me is to come to do this validation of our API, make recommendations for additional detections, which would include some of those, but the end result would be a proposal to you.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "This is where we think we can get with our existing AI.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "These are the things that would have to be developed for you, but we won't know any of that until we get some of this data, until we.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Understand, and I think that's the part too, that I want to be able to explain to our coo that we're doing an experiment here.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "There's no obligation. This is like you checking us out as much as us checking you out. How would this work with an organization like us?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Right, right. Do you think you need something more than the LOI in the deck to kind of.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "If you can give me just a quick minute to skim through it, I think I'm going to have some questions, but let me just remind myself what all is in here.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, I sent this a while ago.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And I know you did.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah. I haven't really looked at it. Yeah, specs so I'm looking at the customer obligations.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So agree upon format of data file with company to rule out data processing and adjusting questions in advance.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "To me, it's like, is that mp4? Is that what you're asking about?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Like data file?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That's what we're providing is the data file. Is this recording. So it'd be like an mp4, right?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Okay. It depends what you have.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "That's fine. Is that the kind of thing that we are looking for then? Yes, egmp four.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "It doesn't have to be like, yeah. And in fact, we have a data ingestion.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I'll get you some examples, but I. Don'T want to make this more complicated than it needs to be.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And I think the data files we're talking about are recordings of role plays. So then we're talking about a format of a data file that is a recording.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So something like mp4 or equivalent. If it's not mp4, I know I've had some mp4 s, but I'll have to go back and look and see. I'm pretty sure everything we have is mp4.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, I think an mp4 would be great. It could be a transcript, it could be the Audio, it could be video. It depends on whatever you have.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah, but I'm pretty sure it's going to be mp4. Okay, so it could be or mp3.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, I'll just leave it at that because I know we'll have a recording and transcripts, I think would be less valuable. So I know it's going to be one of those two.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Okay. And then I'm going to just add here. So this is an example data. Okay. I'll just open the link. So you can see this talks about all the different types of files that we can get.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But like CSV, JsON, VTT, MP4 is fine as well.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah. And this is the playing dumb part.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "It's like, I don't even want my coo to be thinking about all that stuff right now, to be honest.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I want to have her go through and say, oh, yeah, we can do that. That's not a problem. And then we can worry about the details if we need to later.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, that makes sense. Okay, so I actually don't want that in the document because I think she would end up like, oh, what's this? Oh, what's that? Oh, what's this?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah, analysis paralysis.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Sure, I'm interested in that, but I'm not going to put it in.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "All right, so then provide necessary specs to Mpathic for the integration delivery of the pilot application.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So tell me about what that is about. Is that getting near, where is that on my letter? Abcde. Where is that?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Because that seems like, sounds like workflows and swimlands.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Sure. So this is really like, how are you going to get the recordings to us?",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay. Not how I read it at all.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah. Okay. I can put them in a folder for you basically. Yeah. To me that's, yeah. Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So we're going to get the recordings to you because the integration delivery of the pilot application, that doesn't sound like getting the recordings to us.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That sounds like something else.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Well, part of some of our customers, because we haven't had this discussion about the data yet.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Some of our customers actually want to have our API pull programmatically from their data lake. So you would ingest the data with our ingestion API. But in this case it sounds like.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "You just, I'm just going to propose something here.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I'm going to take that out. This is yours to edit company to roll out.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Okay. And delivery mechanism there. Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So then provide empathic to family checkup fidelity rating manual.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That's fine.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Rating manual.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And I'm going to add the word and tool tools.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Utilize your original. Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Because there's both. Right? There's a tool and then there's a guide to how to use a tool.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So what do we get from this? You go right in here to demonstrating ROI.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "But I'm like, well, okay. I feel like there's some steps missing between that.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I mean, maybe what you're asking for is for this to be laid out sequentially.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Right now it's broken up into our obligations and your obligations.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So these are all the things that you have to do and these are all the things that we have to do, but they're technically not in order.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So, for example, once you give us the ingestion, then we ingest the data. We validate our AI using human raiders and make recommendations.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "We develop a proposal, we send it to you and then you do number four, which is participate in the feedback session.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So I think number five is like one of the very last things.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "You're welcome to put this in sequential order rather than breaking it in terms of your obligations and ours. And I'm also happy to put it into a deck format of like, this is how we're going to work together.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "We want to first just see how the data goes before, we spent a long time on producing things like a roadmap together.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But I would imagine that would be one of the primary outcomes of the pilot.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay, let me feedback to you in the term that are sort of closer to the way that I think I want to be able to discuss this.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So we're going to provide you recordings and a fidelity rating manual, and you're going to ingest that into your process, into your system. Then you're going to use the rating manual to design and the rating tool to design an AI raider, basically.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Well, no, what we would do, because this is the pre pilot before. All we're doing is seeing what are the metrics that we have and what are the metrics that we don't have to see.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Alignment between our existing tool. We would talk to you with a proposal of saying, okay, we have about 90% of your fidelity manual covered with our.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Right, okay, sorry. That's the piece that I needed to articulate. Pause there for a minute. So there's sort of like a gap analysis.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Exactly. We can call this a gap analysis.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah. So we provide the recordings in the fidelity rating manual, and you basically provide us a gap analysis and say we've got whatever percentage of this covered with our existing.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And how would I say this? How would you prove that? I don't know how to word that, but how would you demonstrate that?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "90% average. Right. With the human rater.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So we would basically first do a crosswalk between your fidelity metric on the construct level.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Then we'd run the AI, and then we'd have the human readers validate whether the AI was detecting things accurately.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Part of that. But you would be able to see the crosswalk between.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Here's how you defined this construct in the family check up.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And then this is what our AI picked up at the utterance level.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So even you could validate.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah. So let me ask this, because our fidelity management model is that we train supervisors in the family checkup model to do the ratings.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So if a human raider is going to be rating a session, they need to be a trained supervisor according to our fidelity model.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Right. What we're doing is simply aligning the definitions in the fidelity rating model to the things that we have in our AI. And you could make the decision whether or not those align.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But our human raiders are going to review our outputs to ensure that they at least meet the constructs defined. Okay, so let's take a simple example, like an open ended question.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I've got it. I think I can demonstrate to you whether I've got it or not.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I might not have got it, but by proposing an additional step, which grin maybe you have in mind. I just want to make explicit.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Sure.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "As part of that gap analysis, I think there needs to be some input from under customer obligations that an, you know, which would be a paid resource on the.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "We have, we have access to their time, would participate in the validation of the gap analysis or something today.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah. I think what will happen is after we do ours, we'll send it to you.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And so you can do that. Yeah. How many hours might that take somebody on our side to do that validation? Is that going to be like a couple of hours or 8 hours?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "It depends on how much data you send to us.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So if we send you 8 hours a day, whatever. Yeah. Okay. Because it's normally like one to one, it takes roughly the same amount of time to rate a session as it did to record the session. Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "If you already had a human rater review, it will make it a lot easier than recordings that already have a validation and a ground truth.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Right. And that is the case. So then we don't need that step.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "No. Yeah, but our humans will still look at what our AI output is to validate it, to ensure that construct is defined, aligned. And you will be able to look at that as well.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Between the alignment of RA and what. Your prior human raiders did and what I'm thinking would be prudent, would be, before any proposal gets developed, is for that NPS supervisor person, a qualified raider, even if that session has already been rated to go back and then look at what the ratings were and what the AI is telling them, and have that person be that sort of source of truth accuracy to gap analysis.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So it's sort of, what do we want to call that? A sort of gap analysis validation?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "I think though what will happen, honestly, is that you'll give us your fidelity manual, we'll compare it to our existing models, and there will already be a gap analysis right there of like we just don't measure X, Y and Z thing.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And so that's kind of part of why we're doing this as an initial step is just to see how much do we have versus how much do we need to build.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "The proposal will be about that.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "How much work is there to get AI modeling of your fidelity metric?",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Or do we already have enough that our out of the box product could be used?",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And I want to just get expectations that the validation of the gap analysis that is generated by mpathic is an essential part of this process moving forward smoothly.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And that will take an investment of time on our part, but that's it. And I actually know, I'm just thinking out loud.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "There's probably different resources that might be interested in supporting that process. I might be able to get one of the researchers at the University of Oregon who's supporting research on this project to do that validation work.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So it might not actually be an NPS project per know, because that's what my coo gets. Touchy are my. What are my limited resources getting used for? What am I getting out of it?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Right. Well, again, if you're sending us data where a human raider has already rated it, then.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah, it might not.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "But I would rather be on the conservative side and just say going in, it could take up to an equivalent amount of time as the length of the recording of the sessions to do this. Great.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Yeah. Okay. Basically to jump ahead now, like whatever, five or ten steps.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "The proposal would be to develop AI based rating capabilities for recorded sessions of the family checkup that nps could use for its own purposes for a fee, obviously to do rating of sessions because that's part of our job is to rate the sessions of our customers and qualify them.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And it could also be a service that we resell to our customers who are trying to monitor fidelity for themselves.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Absolutely.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Thing I would put into this success criteria.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "We're saying we're demonstrating ROI because we can automatically identify behaviors. Then we can also identify behaviors that relate to you. We can make a statement of phase work if there needs things.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "And then we have this idea of this white label so that you can receive automatic feedback, self guided supervision, and then also back some of what you're doing with science, given that you'll be able to review this at scale.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "So if you think of any other outcomes, I guess I would put them in this little section again.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Typically what we like to do with customers is get this kind of alignment before going into the pilot together and signing off on this.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "But this isn't binding. In a sense. This is just like.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "This is our idea for how we might work together and we'll change as we get the data and different things.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Yeah, I appreciate that it's in an Loi format because I like Lois, a lot of lois in my life.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "And I'm going to take license with the content and present it just in an email format as just this is how it's supposed to work.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That sounds good. Yeah, I'm sorry if we didn't. Maybe that was the piece that was.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Missing out of ask and get part. Okay, good. I know we had scheduled this for half an hour and we're about there and I think this is probably a good place to pause, as you suggested.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I will just noodle on this a little bit more. We're expecting more.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "I'll drop you a note.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "When I think it's like, okay, I think this is where it needs to be.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Do you see any issues, problems are we, mismatch of expectations, et cetera?",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "Then you can just check my work for me and then I'll take it up. And timing is good because I've got a board meeting next Tuesday or Wednesday next week, and I don't think there's much left to do here at this point.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "So just a quick check of what I do, and I can work on that this afternoon.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        },
        {
            "input": "That sounds good. Let us know if you have more questions. And I'm so sorry that that wasn't clear with the ask and get.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "We could have just jumped on a call and gone through this, but I'm glad we have what we do going forward.",
            "speaker": {
                "id": "Grin Lord",
                "role": "mpathic"
            }
        },
        {
            "input": "Okay. All right, thank you. Bye.",
            "speaker": {
                "id": "John Smith",
                "role": "Acme, Co."
            }
        }
    ]
}