import React from 'react'
import { Component } from 'react'
import { ApplicationPaths, QueryParameterNames } from './AuthorizationConstants'
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    let returnUrl = "/";
    if (this.props.path) {
      var link = document.createElement("a");
      link.href = this.props.path;
      returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    }
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    if (!ready) {
      return <div></div>;
    } else if (!authenticated) {
      window.location.href = redirectUrl;
    } else {
      const { element } = this.props;
      return element;
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
