import RadarChartComponent from "./Charts/RadarChart";
import Table from "react-bootstrap/Table";
import { renderTable, mergeTableData, mergeChartData } from "../helpers/common";

const BehaviorCounts = ({ apiData }) => {
  let barChartData = [];
  let tableData;
  let speakerNames = [];
  let composite = false;

  if (apiData.behavior_counts !== undefined) {
    tableData = apiData.behavior_counts;
    barChartData = Object.entries(tableData).map(([label, value]) => ({
      name: label,
      value,
    }));
  } else {
    composite = true;
    barChartData = mergeChartData(apiData, "behavior_counts");
    tableData = mergeTableData(apiData, "behavior_counts");
    speakerNames = apiData.map((speaker) => speaker.id);
  }

  return (
    <> 
      <RadarChartComponent data={barChartData} speakers={speakerNames} composite={composite} />
      <Table striped bordered hover size="sm" className={`w-75 mx-auto composite-${composite}`}>
        <thead>
          <tr>
            <th>Behavior</th>
            <th className="text-center">Count</th>
          </tr>
        </thead>
        <tbody>{renderTable(tableData)}</tbody>
      </Table>
    </>
  );
};

export default BehaviorCounts;
