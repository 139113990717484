import Table from "react-bootstrap/Table";

export const chartColors = ['#8f006b', '#f3ac02', '#1d8587', '#66bb03'];

export const renderContributingBehaviors = (obj) => {
  if (obj.contributing_behaviors.length > 0) {
    return obj.contributing_behaviors.map((behavior, index) => (
      <ul
        key={`${behavior?.behavior}-${index}`}
        className={`contributing-behaviors ${behavior?.influence_on_score}`}
      >
        {Object.entries(behavior).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong>{" "}
            {typeof value === "object"
              ? renderContributingBehaviors(value)
              : value}
          </li>
        ))}
      </ul>
    ));
  }
};

export const renderContributingBehaviorsTable = (obj) => {
  if (obj.contributing_behaviors.length > 0) {
    return obj.contributing_behaviors.map((behavior, index) => (
      <Table
        key={`${behavior?.behavior}-${index}`}
        hover
        responsive
        size="sm"
        borderless={true}
        className={`contributing-behaviors mx-auto f5 ${behavior?.influence_on_score}`}
      >
        <tbody>
          {Object.entries(behavior).map(([key, value]) => (
            <tr key={`${key}-${index}`}>
              <td width="55%">
                <strong>{key}</strong>
              </td>
              <td className="text-start">
                {typeof value === "object"
                  ? renderContributingBehaviors(value)
                  : value}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ));
  }
};

export const renderKeyValuePairs = (obj) => {
  return Object.entries(obj).map(([key, value]) => (
    <div className="flex flex-row justify-between w-100 ph3" key={key}>
      <span>{key}: </span>
      <span>{typeof value === "object" ? renderKeyValuePairs(value) : value}</span>
    </div>
  ));
};

export const renderTable = (obj) => {
  return Object.entries(obj).map(([key, value]) => (
    <tr key={key}>
      <td>{key}</td>
      <td className={typeof value === "object" ? "text-left" : "text-center"}>
        {typeof value === "object" ? renderKeyValuePairs(value) : value}
      </td>
    </tr>
  ));
};

export const fixDecimalPlaces = (number) => {
  return Number((number*100).toFixed(0));
};

export const convertAllNumbers = (dataObj) => {
  Object.keys(dataObj).forEach(index => {
    if (!Number.isInteger(dataObj[index])) {
      dataObj[index] = Number((dataObj[index]*100).toFixed(0));
    }
  });
  return dataObj;
}

export const mergeTableData = (data, targetAnalytic) => {
  let tableData = {};
  if (typeof data[0].analytics[targetAnalytic] === 'object') {
    tableData = {...data[0].analytics[targetAnalytic]};
    for (const [key] of Object.entries(tableData)) {
      let matches = {};
      data.map((speaker) => {
        let match = speaker.analytics[targetAnalytic][key];
        if (match !== undefined) {
          if (!Number.isInteger(match)) {
            match = fixDecimalPlaces(match);
          }
          matches[speaker.id] = match;
        }
        return matches;
      })
      tableData[key] = matches;
    }
  } else {
    tableData['utterance_count'] = 0;
    tableData['talk_turn_count'] = 0;
    for (const [key] of Object.entries(tableData)) {
      let matches = {};
      data.map((speaker) => {
        let match = speaker.analytics[key];
        if (match !== undefined) {
          matches[speaker.id] = match;
        }
        return matches;
      })
      tableData[key] = matches;
    }
  }
  return tableData;
};

export const mergeChartData = (data, targetAnalytic) => {
  let behaviorData;
  if (typeof data[0].analytics[targetAnalytic] === 'object') {
    behaviorData = Object.entries(data[0].analytics[targetAnalytic]).map(([label]) => ({name: label}));
    behaviorData.forEach((behavior) => {
      data.map((speaker) => {
        let match = speaker.analytics[targetAnalytic][behavior.name];
        if (match !== undefined) {
          if (!Number.isInteger(match)) {
            match = fixDecimalPlaces(match);
          }
          behavior[speaker.id] = match;
        }
        return behavior;
      })
    });
  } else {
    behaviorData = Object.entries(data[0].analytics).map(([label]) => (
      (typeof data[0].analytics[label] === 'number') && {name: label} 
    )).filter((behavior) => behavior !== false);

    behaviorData.forEach((behavior) => {
      data.map((speaker) => {
        let match = speaker.analytics[behavior.name];
        if (match !== undefined) {
          if (!Number.isInteger(match)) {
            match = fixDecimalPlaces(match);
          }
          behavior[speaker.id] = match;
        }
        return behavior;
      })
    });
  }

  return behaviorData;
};