import React, { Component } from 'react';
import { Link } from "react-router-dom";

export class Unauthorized extends Component {
  static displayName = Unauthorized.name;

  render() {
    return (
      <div>
        <h2>Unauthorized</h2>
        <p>We're sorry! This app is not available for your login.</p>
        <Link className='button' to="/auth/logout" target='_top'>Sign in again</Link>
      </div>
    );
  }
}
