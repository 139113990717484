export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  Logout: 'logout',
};

export const LoginActions = {
  Login: 'login',
};

const prefix = '/auth';

export const ApplicationPaths = {
  Login: `${prefix}/${LoginActions.Login}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
};
