export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  async isAuthenticated() {
    const user = await this.getUser();
    return user?.isAuthenticated;
  }

  async getUser() {
    if (this._user) {
      return this._user;
    }

    const response = await fetch('/auth/getCurrentUser');
    const user = await response.json();

    //console.log('user:', user);

    if (user) {
      this.updateState(user);
    }

    return this._user;
  }

  updateState(user) {
    this._user = user;
    this._isAuthenticated = this._user?.isAuthenticated;
    this.notifySubscribers();
  }

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
      .filter(element => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;
