const BubbleLoader = (show: any) => {
  return (
    <>
      {show.show && (
        <div className="bubble">
          <div className="dot"></div>
        </div>
      )}
    </>
  );
};

export default BubbleLoader;
