import React, { useState } from "react";
import Button from "../components/base/Button";
import { useNavigate } from "react-router-dom";
import { getInsightsAPI } from "../api/api-common";
import LoadingAnimation from "./base/LoadingAnimation";
import MpathicStatsCard from "../components/MpathicStatsCard";
const insights = require("../assets/insights.png");

const Upload = () => {
  const [insightActive, setInsightActive] = useState<boolean>(false);
  const [responseMetrics, setResponseMetrics] = useState({});
  const [synchronyMetrics, setSynchronyMetrics] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [, setFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<any>(null);
  const [fileActive, setFileActive] = useState<boolean>(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const content = JSON.parse(event.target.result as string);
          setFileContent(content.talk_turns);
        }
      };
      reader.readAsText(selectedFile);
    }
  };

  const handleProcessFile = () => {
    if (fileContent) {
      setFileActive(true);
      handleInsights();
    } else {
      // Handle the case when no file is selected or the file content is not available
      console.error("Please select a file before processing.");
    }
  };

  const handleInsights = () => {
    if (insightActive) {
      setInsightActive(false);
    } else if (Object.keys(responseMetrics).length !== 0 && Object.keys(synchronyMetrics).length !== 0) {
      setInsightActive(true);
    } else {
      setIsLoading(true);
      getInsightsAPI(fileContent)
        .then((res) => {
          setResponseMetrics(
            res && res.data.analytics.conversation_analytics.metrics
          );
          setSynchronyMetrics(res && res.data.analytics.synchrony_scores);
          setInsightActive(true);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          alert(err);
          console.log(err);
        });
    }
  };

  return (
    <div className="container insight-container">
      {isLoading && <LoadingAnimation />}

      <div className="flex flex-row flex-wrap">
        <div className="insight-meeting-left w-100 w-20-l pa4">
          <div className="breadcrumbs f6">
            <a href="/">Home</a> &nbsp; &gt; &nbsp;
            <a href="/insights">Insights</a> &nbsp; &gt; &nbsp;
            <span>Upload</span>
          </div>

          <div className="flex flex-column flex-row-m flex-column-l justify-between items-start items-center-m pt4 pv4-l">
            <div>
              <img src={insights} alt="realtime" />
            </div>
            <div>
              <h3>
                mpathic Insights
                <br />
                <span className="normal">{">"} Upload</span>
              </h3>
            </div>
            <div className="insight-meeting-buttons">
                <Button
                  className="btn primary-btn meeting-btn mb3"
                  onClick={() => navigate("/insights")}
                >
                  Try a different insight
                </Button>
            </div>
          </div>
        </div>

        <div className="insight-meeting-center w-100 w-50-m w-40-l w-50-xl pa4 pt5-l">
          <div className="vh-25 vh-75-ns overflow-auto bg-white br4 pa4 pa5-l box-shadow">
            <div>
              {fileActive ? (
                <div
                  className="message-container"
                  contentEditable="true"
                  suppressContentEditableWarning={true}
                >
                  {fileContent &&
                    fileContent.map((talk: any, i: any) => (
                      <div className="transcript-message pb3" key={i}>
                        <div className="message">
                          <span className="user b">{talk.speaker.id}:</span>
                          <span> {talk.input}</span>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <>
                  <h4>Upload a transcript to see the conversation</h4>
                  <input type="file" onChange={handleFileChange} />
                  <button onClick={handleProcessFile}>Process File</button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="insight-meeting-right w-100 w-50-m w-40-l w-30-xl pa4 pa2-m pt5-l">
          <div className="flex flex-row flex-wrap mb3">
            <Button
              className={`${insightActive ? "active" : ""} mr3 mb3`}
              onClick={handleInsights}
            >
              {insightActive ? "Hide" : "Show"} mpathic Insights
            </Button>
            <Button
              className="btn primary-btn meeting-btn mb3"
              onClick={() => window.location.reload()}
            >
              Upload another file
            </Button>
          </div>

          {insightActive && responseMetrics && synchronyMetrics && (
            <div
              className={`vh-70 overflow-auto apicard-wrapper ${
                insightActive ? "active" : ""
              }`}
            >
              <MpathicStatsCard
                statsData={responseMetrics}
                synchronyData={synchronyMetrics}
                highlightsData={""}
                lowLightsData={""}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Upload;
