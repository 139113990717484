import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './AuthorizationConstants';

export class LoginMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.emailAddress
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(loginPath);
    } else {
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, logoutPath, logoutState) {
    return (<Fragment>
      <Link replace className='button button-sm' target='_top' to={logoutPath} state={logoutState}>Logout <span className='icon-log-out'></span></Link>
    </Fragment>);
  }

  anonymousView(loginPath) {
    return (<Fragment>
      <Link className='button button-sm' target='_top' to={loginPath}>Login <span className='icon-log-in'></span></Link>
    </Fragment>);
  }
}
