import { useCallback, useEffect, useRef, useState } from "react";
import MessageCard from "../components/base/MessageCard";
import Button from "../components/base/Button";
import Icon from "../assets/icons/icon";
import suggestionsImage from "../assets/suggestions.png";
import buttonLogo from "../assets/mpathic-m.png";
import logo from "../assets/mpathic-m.png";
import {
  acceptText,
  countWords,
  highlightText,
  sanitize,
} from "../helpers/util";
import { getSuggestionsAPI } from "../api/api-common";
import SuggestionCard from "../components/base/SuggestionCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ContentEditable from "../components/base/ContentEditable";
import { ChatGPT4 } from "../helpers/chatGPT";
import { useDebounce } from "../hooks/debounce";
import BubbleLoader from "../components/base/BubbleLoader";

const Chat = () => {
  const [behaviorsAPIResponse, setBehaviorsAPIResponse] = useState<any>([]);
  const [masterArray, setMasterArray] = useState<any>([]);
  const [iconStatus, setIconStatus] = useState<boolean>(false);
  const [responseCount, setResponseCount] = useState<number>(0);
  const [showCardTooltip, setShowCardTooltip] = useState<boolean>(false);
  const [isFetchingApi, setIsFetchingApi] = useState<boolean>(false);
  const [shouldFireAPICall, setShouldFireAPICall] = useState<boolean>(true);
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const activeAcceptBtn = useRef(false);
  const [gpt4ResponseLoader, setGpt4ResponseLoader] = useState<boolean>(false);
  const [chatStack, setChatStack] = useState([
    {
      role: "system",
      content:
        "You are an angry employee who is going on vacation and doesn't really care about their job.  You will always respond in antagonistic tone and refuse to adhere to deadlines or requests from your boss.  If I send you something that is empathetic however, you will change your tone and start to listen.",
    },
    {
      role: "assistant",
      content:
        "Hi, I'm taking the rest of the week off to hang with some of my college buddies. The report you wanted isn't quite done, but I'm sure you can figure  out how to finish it. Biyeee!",
    }
  ]);

  const userData = useRef("");
  const [editableHTML, setEditableHTML] = useState<any>({
    html: "",
    editable: true,
  });

  const useRefCallback = <T extends any[]>(
    value: ((...args: T) => void) | undefined,
    deps?: React.DependencyList
  ): ((...args: T) => void) => {
    const ref = useRef(value);

    useEffect(() => {
      ref.current = value;
    // eslint-disable-next-line
    }, deps ?? [value]);

    const result = useCallback((...args: T) => {
      ref.current?.(...args);
    }, []);

    return result;
  };

  // Calls API with text from input field
  const handleInputChange = useRefCallback((evt) => {
    setShouldFireAPICall(true);
    setEditableHTML({ html: evt.target.value, editable: true });
  }, []);
  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);

  // Returns a response to the newly typed message
  const handleNewMessage = () => {
    // eslint-disable-next-line
    let sanitizeData = editableHTML.html.replace(/(<([^>]+)>)/gi, ""); 
    // eslint-disable-next-line
    sanitizeData = sanitizeData.replace(/\&nbsp;/g, "");
    setIconStatus(false);
    setResponseCount(0);
    setShowCardTooltip(false);
    setEditableHTML({ html: "", editable: true });
    setMasterArray([]);
    userData.current = "";
    
    ChatGPT4(
      chatStack,
      setChatStack,
      sanitizeData,
      setGpt4ResponseLoader
    );
  };

  const deleteByIndex = (index: any) => {
    setMasterArray((oldValues: any[]) => {
      return oldValues.filter((_, i) => i !== index);
    });
    console.log('deleteByIndex index: ', index);
    console.log('deleteByIndex masterArray: ', masterArray);
  };

  const acceptTextByIndex = (index: any) => {
    const acceptedItem = masterArray.filter(
      (item: { isSuggestionAccepted: boolean }) => {
        return item.isSuggestionAccepted === true;
      }
    );

    if (acceptedItem.length > 0) {
      let sanitizeData = acceptText(
        editableHTML.html,
        acceptedItem[0].original,
        acceptedItem[0].suggestion
      );

      deleteByIndex(index);

      setEditableHTML({
        html: sanitizeData,
        editable: true,
      });
      userData.current = sanitizeData;
    }
  };

  const closeTooltip = () => {
    setShowCardTooltip(false);
    setEditableHTML({ html: sanitize(editableHTML.html), editable: true });
    userData.current = sanitize(editableHTML.html);
  };

  const handlePrevSlide = () => {
    if (activeSlide !== 0) {
      setActiveSlide(activeSlide - 1);
      let items = [];
      for (let i = 0; i < masterArray.length; i++) {
        let item = masterArray[i];
        if (i === activeSlide - 1) {
          item.selected = true;
          console.log('handlePrevSlide item.suggestion: ', item.suggestion);
          (item.suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
        } else {
          item.selected = false;
        }
        items.push(item);
      }
      setMasterArray([...items]);
    }
  };

  const handleNextSlide = () => {
    if (activeSlide !== masterArray.length) {
      setActiveSlide(activeSlide + 1);
      let items = [];
      for (let i = 0; i < masterArray.length; i++) {
        let item = masterArray[i];
        if (i === activeSlide + 1) {
          item.selected = true;
          console.log('handleNextSlide item.suggestion: ', item.suggestion);
          (item.suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
        } else {
          item.selected = false;
        }
        items.push(item);
      }
      setMasterArray([...items]);
    }
  };
  const handleDismissSuggestion = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    if (masterArray.length === index) {
      // if last slide
      if (masterArray.length > 1) {
        // if last slide but not only slide
        setActiveSlide(activeSlide - 1);
        setResponseCount(responseCount - 1);

        let items = masterArray;
        let item = masterArray[activeSlide - 1];
        item.selected = true;
        items[activeSlide - 1] = item;
        setMasterArray([...items]);
        deleteByIndex(activeSlide);
      } else if (masterArray.length === 1) {
        // if last and only slide
        setActiveSlide(0);
        setResponseCount(0);
        setShowCardTooltip(false);
        setMasterArray([]);
        setEditableHTML({ html: sanitize(editableHTML.html), editable: true });
        userData.current = sanitize(editableHTML.html);
      }
    } else if (masterArray.length > index) {
      setResponseCount(responseCount - 1);
      let items = masterArray;
      let item = masterArray[activeSlide + 1];
      item.selected = true;
      items[activeSlide + 1] = item;
      setMasterArray([...items]);
      deleteByIndex(activeSlide);
    }
  };

  const handleAcceptSuggestion = (e: any, index: number) => {
    if (masterArray.length === index) {
      if (masterArray.length > 1) {
        /**** Set suggestion accepted *****/
        let items = masterArray;
        let item = masterArray[activeSlide];
        item.isSuggestionAccepted = true;
        items[activeSlide] = item;
        /**** end *****/
        /** Set selected item */
        let item2 = masterArray[activeSlide - 1];
        item2.selected = true;
        items[activeSlide - 1] = item2;
        /* end */
        setMasterArray([...items]);
        acceptTextByIndex(activeSlide);
        (item2.suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
        setActiveSlide(activeSlide - 1);
      } else if (masterArray.length === 1) {
        setActiveSlide(0);
        setShowCardTooltip(false);
        let items = masterArray;
        let item = masterArray[0];
        item.isSuggestionAccepted = true;
        items[0] = item;
        setMasterArray([...items]);
        acceptTextByIndex(0);
        (item.suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
      }
    } else if (masterArray.length > index) {
      let items = masterArray;
      let item = masterArray[activeSlide];
      item.isSuggestionAccepted = true;
      items[activeSlide] = item;

      let item2 = masterArray[activeSlide + 1];
      item2.selected = true;
      items[activeSlide + 1] = item2;
      setMasterArray([...items]);
      acceptTextByIndex(activeSlide);
      (item2.suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
    }
    setResponseCount(responseCount - 1);
    setShouldFireAPICall(false);
  };

  // Returns Suggestion from API based on input field text
  useEffect(() => {
    if (shouldFireAPICall) {
      setShowCardTooltip(false);
      setResponseCount(0);
      if (countWords(editableHTML.html) >= 1) {
        userData.current = sanitize(editableHTML.html);
        setIsFetchingApi(true);

        getSuggestionsAPI(sanitize(editableHTML.html))
        .then((res) => {
          let customAPIResponse: {
            confidence: number;
            name: string;
            suggestion: any;
            tip: any;
            original: any;
          }[] = [];
          const response = Object.values(res.data.detections);
          response.forEach((item: any, index: number) => {
            if (item.behaviors) {
              item.behaviors.forEach((behaviorItem: any, behaviorIndex: number) => {
                customAPIResponse.push({
                  confidence: behaviorItem.confidence,
                  name:       behaviorItem.name,
                  suggestion: behaviorItem.suggestion,
                  tip:        behaviorItem.tip,
                  original:   item.text
                });
              });
              setIconStatus(true);
            }
          });
          setBehaviorsAPIResponse(customAPIResponse);
          setIsFetchingApi(false);
        })
        .catch((err) => {
          alert(err.message + ". Please try again.");
          setIconStatus(false);
          setIsFetchingApi(false);
          console.log(err);
        });

      } else {
        setIconStatus(false);
      }
    }
  // eslint-disable-next-line
  }, [editableHTML]);

  useEffect(() => {
    setResponseCount(behaviorsAPIResponse.length);
    setMasterArray([]);
    // eslint-disable-next-line
    behaviorsAPIResponse.map((item: any, index: number) => {
      setMasterArray((masterArray: []) => [
        ...masterArray,
        {
          original: item.original,
          behavior: item.name,
          suggestion: item?.suggestion?.text,
          tip: item?.tip?.text,
          selected: index === 0 ? true : false,
          isSuggestionAccepted: false,
        },
      ]);
    });
  }, [behaviorsAPIResponse]);

  useEffect(() => {
    setShouldFireAPICall(false);
    const selectedItem = masterArray.filter((item: { selected: boolean }) => {
      return item.selected === true;
    });
    if (selectedItem.length > 0 && showCardTooltip) {
      if (selectedItem.length > 0) {
        setEditableHTML({
          html: highlightText(editableHTML.html, selectedItem[0].original),
          editable: true,
        });
        userData.current = highlightText(
          editableHTML.html,
          selectedItem[0].original
        );
      }
      if (selectedItem.length === 0) {
        setEditableHTML({ html: sanitize(editableHTML.html), editable: true });
        userData.current = sanitize(editableHTML.html);
      }
    }
    // eslint-disable-next-line
  }, [masterArray, showCardTooltip]);

  const handleFloatingIconClick = () => {
    if (editableHTML.html === "") { return; }
    setShouldFireAPICall(false);
    setShowCardTooltip(true);
    const selectedItem = masterArray.filter((item: { selected: boolean }) => {
      return item.selected === true;
    });
    if (selectedItem.length !== 0) {
      setEditableHTML({
        html: highlightText(editableHTML.html, selectedItem[0].suggestion),
        editable: true,
      });
      userData.current = highlightText(
        editableHTML.html,
        selectedItem[0].suggestion
      );
      (selectedItem[0].suggestion) ? activeAcceptBtn.current = true : activeAcceptBtn.current = false;
    }
    if (selectedItem.length === 0) {
      setEditableHTML({ html: sanitize(editableHTML.html), editable: true });
      userData.current = sanitize(editableHTML.html);
    }
  };

  return (
    <div className="suggestions-container">
      <div className="flex flex-row flex-wrap">

        <div className="suggestions-left w-20-l pa4">
          <div className="breadcrumbs f6">
              <a href="/">Home</a> &nbsp; &gt; &nbsp;
              <a href="/suggestions">Suggestions</a>
          </div>
          <div className="flex flex-column flex-row-m flex-column-l justify-between items-start items-center-m pt4 pv4-l">
            <div>
              <img src={suggestionsImage} alt="Suggestions" />
            </div>
            <div>
              <h3>
                mpathic Suggestions
              </h3>
              <p>You just received a troubling message from one of your employees.</p>
            </div>
          </div>
        </div>

        <div className="suggestions-center w-50-m w-40-l w-50-xl pa4 pt5-l">
          <div className="vh-75 overflow-auto bg-white br4 box-shadow">
            <div className="correction-window">
              <div className="correction-window-body">
                <div className="correction-wrapper">
                  <div className="place-holder"></div>
                  {chatStack &&
                    chatStack.map((chat, index) => {
                      return (
                        <>
                          {chat && chat.role !== "system" && (
                            <MessageCard
                              key={index}
                              className={`message-card ${
                                (chat.role === "assistant") === true
                                  ? "primary-message-card"
                                  : "secondary-message-card"
                              }`}
                            >
                              {chat.content}
                            </MessageCard>
                          )}
                        </>
                      );
                    })}
                  <BubbleLoader show={gpt4ResponseLoader} />
                </div>
              </div>
              <div className="form-container">
                <div className="editor-wrapper">
                  <ContentEditable
                    className="editable custom-textarea v2"
                    tagName="div"
                    html={editableHTML.html}
                    disabled={!editableHTML.editable} // use true to disable edition
                    onChange={debouncedHandleInputChange}
                    placeholder="Type your response here..."
                  />
                  <div
                    className={`floating-icon${
                      iconStatus ? " active" : ""
                    } ${isFetchingApi ? "loading" : ""}`}
                    onClick={handleFloatingIconClick}
                  >
                    <img src={buttonLogo} alt=".." />
                    {!isFetchingApi && responseCount !== 0 && (
                      <span>{responseCount}</span>
                    )}
                  </div>
                </div>
                <Button className={`btn disabled-${editableHTML.html.trim() === ''}`} onClick={handleNewMessage} disabled={editableHTML.html.trim() === ''}>
                  <Icon kind="send" />
                </Button>

                {showCardTooltip && masterArray.length !== 0 && (
                  <SuggestionCard className={`suggestion-card sidebar`}>
                    <div className="card-header">
                      <img src={logo} alt="logo" />
                      <span onClick={closeTooltip}>
                        <Icon kind="iconClose" />
                      </span>
                    </div>
                    <Carousel
                      selectedItem={activeSlide}
                      showArrows={false}
                      showStatus={false}
                      showIndicators={false}
                      showThumbs={false}
                      dynamicHeight
                    >
                      {masterArray.map((item: any, index: any) => {
                        return (
                          <div
                            className={`carousel item-${index}`}
                            key={index}
                          >
                            <div className="metric">
                              {item && item.behavior}
                            </div>
                            <div className="original-text i">
                              "{item && item.original}"
                            </div>
                            {item && item.tip && (
                            <div className="prompt">
                              <span className="icon-info"></span> {item.tip}
                            </div>
                            )}
                            {item && item.suggestion && (
                              <div className="mpathic-suggestion">
                                <span className="icon-checkmark-circle"></span> {item.suggestion}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Carousel>
                    <div className="btn-wrapper">
                    {responseCount > 1 && (
                        <div className="carousel-btns">
                          <span
                            className={`left${
                              activeSlide === 0 ? " disabled" : ""
                            }`}
                            onClick={handlePrevSlide}
                          >
                            <Icon kind="leftArrow" />
                          </span>
                          <span
                            className={`right${
                              activeSlide === masterArray.length - 1
                                ? " disabled"
                                : ""
                            }`}
                            onClick={handleNextSlide}
                          >
                            <Icon kind="leftArrow" />
                          </span>
                        </div>
                      )}
                      {activeAcceptBtn.current && (
                        <div className="btns">
                          <button
                            className="accept"
                            onClick={(e) =>
                              handleAcceptSuggestion(e, activeSlide + 1)
                            }
                          >
                            Accept
                          </button>
                          <button
                            className="dismiss"
                            onClick={(e) =>
                              handleDismissSuggestion(e, activeSlide + 1)
                            }
                          >
                            Dismiss
                          </button>
                        </div>
                      )}

                    </div>
                    <div className="arrow"></div>
                  </SuggestionCard>
                )}
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default Chat;
