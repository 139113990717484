import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Rnd } from 'react-rnd';

import { botCustomChartColors, botRndStyle } from '../../helpers/botCommon';

interface BotPieChartProps {
    data: any;
    position: any;
    size: any;
    title: string;
}

const BotPieChart: React.FC<BotPieChartProps> = ({ data, position, size, title }) => {
    return (
        <>
            <Rnd
                style={botRndStyle}
                default={({
                    x: position.x,
                    y: position.y,
                    width: size.width,
                    height: size.height
                })}
            >
                <div style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", textAlign: "center", width: "100%" }}>{title}</div>
                <ResponsivePie
                    colors={botCustomChartColors}
                    data={data}
                    margin={{ top: 40, right: 120, bottom: 80, left: 120 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 0,
                            translateY: 56,
                            itemsSpacing: 0,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            itemDirection: 'left-to-right',
                            itemOpacity: 1,
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />

            </Rnd>
        </>
    )
};

export default BotPieChart;
