import React from "react";
import Accordion from "./base/Accordion";
import InsightCircularbar from "./base/InsightCircularbar";

interface IProps {
  statsData: any;
  synchronyData: any;
  highlightsData: any;
  lowLightsData: any;
}

const MpathicStatsCard: React.FC<IProps> = ({
  statsData,
  synchronyData,
  highlightsData,
  lowLightsData,
}) => {

  let synchronyScore = 0;
  if (synchronyData[0].synchrony_score) {
    synchronyScore = synchronyData[0].synchrony_score.toFixed(1);
  }

  const renderColor = (score: number) => {
    if (score <= 2) {
      return "#FC0002";
    }
    if (score > 2 && score <= 3) {
      return "#F96264";
    }
    if (score > 3 && score <= 5) {
      return "#FCD703";
    }
    if (score > 5 && score <= 8) {
      return "#66BB03";
    }
    if (score > 8 && score <= 10) {
      return "#4C8E00";
    }
    return "";
  };

  return (
    <div className="mpathic-stats-container">
      <h5>Insights</h5>
      <div className="insight-circularbar-container">
        <InsightCircularbar
          title="Empathy"
          currentValue={statsData.empathy.score}
          baseColor="#f2f2f2"
          completedColor={renderColor(statsData.empathy.score)}
          description="Accurate understanding. 
          Efforts to understand 
          another person’s point of 
          view."
        />
        <InsightCircularbar
          title="Curiosity"
          currentValue={statsData.curiosity.score}
          baseColor="#f2f2f2"
          completedColor={renderColor(statsData.curiosity.score)}
          description="Encouraging others to 
          share more information. 
          Open versus directive."
        />
        <InsightCircularbar
          title="Collaboration"
          currentValue={statsData.collaboration.score}
          baseColor="#f2f2f2"
          completedColor={renderColor(statsData.collaboration.score)}
          description="Sharing power. Inviting 
          another person’s thoughts 
          and accepting autonomy."
        />
        <InsightCircularbar
          title="Synchrony"
          currentValue={synchronyScore}
          baseColor="#f2f2f2"
          completedColor={renderColor(synchronyScore)}
          description="Objective indicator of 
          empathy. Represents 
          unconscious synchrony in 
          language style. "
        />
      </div>
      {highlightsData && (
        <div
          className="highlights-container"
        >
          <div className="highlights-item">
            <h5>
              <span>Highlights</span>
            </h5>
            <Accordion data={highlightsData} />
          </div>
          <div className="highlights-item">
            <h5>
              <span>Growth Area</span>
            </h5>
            <Accordion data={lowLightsData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MpathicStatsCard;
