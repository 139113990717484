import { getChatCompletionAPI } from "../api/api-common";

export function ChatGPT4(
  chatStack: any,
  setChatStack: any,
  sanitizeData: any,
  setGpt4ResponseLoader: any
) {
  setGpt4ResponseLoader(true);
  setChatStack((oldArray: any) => [
    ...oldArray,
    { role: "user", content: sanitizeData },
  ]);
  getChatCompletionAPI(chatStack)
    .then((res) => {
      setChatStack((oldArray: any) => [
        ...oldArray,
        { role: "assistant", content: res.data }
      ]);
      setGpt4ResponseLoader(false)
    })
    .catch((err) => {
      console.log(err);
      setGpt4ResponseLoader(false);
    });
}