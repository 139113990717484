import React from "react";

interface IProps {
  className: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<any>;
  dataCardIndex? : any
}

const SuggestionCard: React.FC<IProps> = ({ className, children, onClick, dataCardIndex }) => {
  return (
    <div className={className} onClick={onClick} data-card-index={dataCardIndex}>
      {children}
    </div>
  );
};

export default SuggestionCard;
