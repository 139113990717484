import React from "react";
import AccordionItem from "./AccordionItem";

interface IProps {
  data: any;
}

const Accordion: React.FC<IProps> = ({ data }) => {
  return (
    <div className="accordion">
      {data &&
        data.map((item: any, index: number) => (
          <AccordionItem item={item} key={index} />
        ))}
    </div>
  );
};

export default Accordion;
