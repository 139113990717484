import React from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { Rnd } from "react-rnd";

import { botCustomChartColors, botRndStyle } from '../../helpers/botCommon';

interface BotRadialBarProps {
    data: any;
    position: any;
    size: any;
    title: string;
  }

  const BotRadialBar: React.FC<BotRadialBarProps> = ({ data, position, size, title }) => {
    return (
         <>
        <Rnd
            style={botRndStyle}
            default={({
                x: position.x,
                y: position.y,
                width: size.width,
                height: size.height
            })}
        >
            <div style={{position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", textAlign: "center", width: "100%"}}>{title}</div>
            <ResponsiveRadialBar
                data={data}
                colors={botCustomChartColors}
                valueFormat=" >-.0o"
                padding={0.4}
                cornerRadius={2}
                margin={{ top: 80, right: 120, bottom: 40, left: 40 }}
                radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
                legends={[
                    {
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 70,
                        translateY: -70,
                        itemsSpacing: 2,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemTextColor: '#999',
                        symbolSize: 18,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        </Rnd>
        </>
    )
}

export default BotRadialBar;